import PropTypes from "prop-types";
import React, { useEffect, useReducer, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Message, Segment, Tab } from "semantic-ui-react";

import { isNil } from "lodash";
import { Config } from "../../config/api";
import { onBannerDismiss, useDebounce } from "../../libs/component_utils";
import Pager from "../../models/pager";
import SiteListService from "../../services/site-list";
import InventoryPageContext from "./context";
import SitesFilter from "./site-list/filter";
import SiteLists from "./site-list/index";

export const InventoryReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_FILTERS":
			let updatedFilters = { ...state };
			let { data } = action;
			updatedFilters.siteList.pager.reset();
			if (data.title) updatedFilters.siteList.filter.setTitle(data.title);
			if (data.status) updatedFilters.siteList.filter.setStatus(data.status);
			return updatedFilters;
		case "LOAD_INDEX":
			const updatedIndex = { ...state };
			updatedIndex[action.data.index].index = action.data.data;
			updatedIndex[action.data.index].pager.setTotal(action.data.meta);
			return updatedIndex;
		case "LOAD_INDEX_IN_PROGRESS":
			const updatedLoad = { ...state };
			updatedLoad[action.data.index]["loading"] = action.data.loading;
			return updatedLoad;
		case "LOAD_ERROR":
			return {
				...state,
				error: action.data?.error,
				errorMessage: action.data?.errorMessage,
			};
		default:
			throw new Error("Method is not implemented");
	}
};

const loadSiteLists = (state, dispatch, page) => {
	dispatch({
		type: "LOAD_INDEX_IN_PROGRESS",
		data: { index: "siteList", loading: true },
	});
	const service = new SiteListService();

	state.siteList.pager.setPage(page);
	let params = state.siteList.pager.toJson();
	if (state.siteList.filter)
		params = Object.assign(params, state.siteList.filter.toRequestJson());

	const request = service.list(params);

	request
		.then((r) => {
			dispatch({ type: "LOAD_INDEX", data: { index: "siteList", ...r } });
			dispatch({
				type: "LOAD_ERROR",
				data: { error: false, errorMessage: null },
			});
		})
		.catch((e) => {
			dispatch({
				type: "LOAD_ERROR",
				data: { error: true, errorMessage: e.message },
			});
		})
		.finally(() => {
			dispatch({
				type: "LOAD_INDEX_IN_PROGRESS",
				data: { index: "siteList", loading: false },
			});
		});
};

function InventoryPage({ history }) {
	const intl = useIntl();
	const location = useLocation();
	const showSuccessMessage = !isNil(history?.location?.state);

	// Filter
	const filter = new SitesFilter();
	filter.reset();

	// Pager
	const pager = new Pager();
	pager.reset();

	// State
	const [currentTab, setCurrentTab] = useState(0);
	const [state, dispatch] = useReducer(InventoryReducer, {
		error: false,
		errorMessage: null,
		siteList: {
			index: [],
			mount: false,
			total_pages: 0,
			loading: false,
			pager,
			filter,
		},
	});

	// Tabs
	const TabLoadFn = [loadSiteLists];
	const TabPanes = [
		{
			menuItem: intl.formatMessage({
				id: "INVENTORY_TAB_SITE_LIST",
				defaultMessage: "Site Lists",
			}),
			render: () => (
				<Segment
					loading={state.siteList.loading}
					basic
					style={{ padding: "0" }}
				>
					<SiteLists />
				</Segment>
			),
		},
	];

	useEffect(() => {
		(async () =>
			TabLoadFn[currentTab](state, dispatch, state.siteList.pager.page))();
	}, [currentTab]);

	const debouncedLoadSiteList = useDebounce(
		Config.search_debounce_delay,
		loadSiteLists
	);

	return (
		<InventoryPageContext.Provider
			value={{ state, dispatch, loadSiteLists: debouncedLoadSiteList }}
		>
			{showSuccessMessage && (
				<SiteListsSuccessMessage details={location.state ?? {}} />
			)}
			<h1>
				{intl.formatMessage({
					id: "HEADING_INVENTORY",
					defaultMessage: "Inventory",
				})}
			</h1>
			<Tab
				menu={{ secondary: true, pointing: true }}
				panes={TabPanes}
				onTabChange={(_e, data) => {
					setCurrentTab(data.activeIndex);
				}}
			/>
		</InventoryPageContext.Provider>
	);
}

/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const SiteListsSuccessMessage = ({ details }) => {
	const intl = useIntl();

	useEffect(() => {
		const timer = setTimeout(onBannerDismiss, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (
		details &&
		details?.action === "created" &&
		details?.type === "site-list"
	) {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_SITE_LIST_CREATED",
					defaultMessage: "Site List successfully created",
				})}
			</Message>
		);
	}

	return null;
};

SiteListsSuccessMessage.propTypes = {
	details: PropTypes.object.isRequired,
};

export default InventoryPage;
