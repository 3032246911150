import AudienceCreatePage from "../components/audience/create";
import AudienceEditPage from "../components/audience/edit";
import { AudiencesPage } from "../components/audience/index";
import CampaignCreatePage from "../components/campaign/create";
import CampaignEditPage from "../components/campaign/edit";
import CampaignsPage from "../components/campaign/index";
import InventoryPage from "../components/inventory";
import SiteListCreatePage from "../components/inventory/site-list/create";
import StrategyCreatePage from "../components/strategy/create";
import StrategyEditPage from "../components/strategy/edit";
import { StrategiesPage } from "../components/strategy/index";

export const Routes = [
	{
		path: "/campaigns",
		component: CampaignsPage,
		title: ["Campaigns"],
	},
	{
		path: "/campaign/create",
		component: CampaignCreatePage,
		title: ["Campaigns", "Create"],
	},
	{
		path: "/campaign/edit/:id",
		component: CampaignEditPage,
		title: ["Campaigns", "Edit"],
	},
	{
		path: "/campaign/:campaign_id/strategies",
		component: StrategiesPage,
		title: ["Strategies"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create",
		component: StrategyCreatePage,
		step: "0",
		title: ["Strategies", "Create", "Marketing Objectives"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id",
		component: StrategyCreatePage,
		step: "0",
		title: ["Strategies", "Create", "Marketing Objectives"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id/supplies",
		component: StrategyCreatePage,
		step: "1",
		title: ["Strategies", "Create", "Supplies"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id/creatives",
		component: StrategyCreatePage,
		step: "2",
		title: ["Strategies", "Create", "Creatives"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id/audiences",
		component: StrategyCreatePage,
		step: "3",
		title: ["Strategies", "Create", "Audiences"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id/locations",
		component: StrategyCreatePage,
		step: "4",
		title: ["Strategies", "Create", "Locations"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id/dayparts",
		component: StrategyCreatePage,
		step: "5",
		title: ["Strategies", "Create", "Daypart"],
	},
	{
		path: "/campaign/:campaign_id/strategies/create/:strategy_id/technology",
		component: StrategyCreatePage,
		step: "6",
		title: ["Strategies", "Create", "Technology"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id",
		component: StrategyEditPage,
		step: "0",
		title: ["Strategies", "Edit", "Marketing Objectives"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id/supplies",
		component: StrategyEditPage,
		step: "1",
		title: ["Strategies", "Edit", "Supplies"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id/creatives",
		component: StrategyEditPage,
		step: "2",
		title: ["Strategies", "Edit", "Creatives"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id/audiences",
		component: StrategyEditPage,
		step: "3",
		title: ["Strategies", "Edit", "Audiences"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id/locations",
		component: StrategyEditPage,
		step: "4",
		title: ["Strategies", "Edit", "Locations"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id/dayparts",
		component: StrategyEditPage,
		step: "5",
		title: ["Strategies", "Edit", "Daypart"],
	},
	{
		path: "/campaign/:campaign_id/strategies/edit/:strategy_id/technology",
		component: StrategyEditPage,
		step: "6",
		title: ["Strategies", "Edit", "Technology"],
	},
	{
		path: "/audiences",
		component: AudiencesPage,
		title: ["Audiences"],
	},
	{
		path: "/audience/create",
		component: AudienceCreatePage,
		title: ["Audiences", "Create"],
	},
	{
		path: "/audience/edit/:audience_id",
		component: AudienceEditPage,
		title: ["Audiences", "Edit"],
	},
	{
		path: "/inventory",
		component: InventoryPage,
		title: ["Inventory"],
	},
	{
		path: "/site-links/create",
		component: SiteListCreatePage,
		title: ["Site List", "Create"],
	},
];
