import lightFormat from "date-fns/lightFormat";
import add from "date-fns/add";
import parseDate from "date-fns/parse";
import {utcToZonedTime} from "date-fns-tz";

import { Config } from "../config/api";
import isBefore from "date-fns/isBefore";
import {RevenueType} from "../models/enum/pixel_revenue_type";

/**
 * verify that target has loaded children
 * @param {object} t
 * @returns {boolean}
 */
export const targetHasChildren = t => {
		return t.has_children && (!t.hasOwnProperty("child") || t.child.length < 1);
}

(function() {
	function decimalAdjust(type, value, exp) {
		if (typeof exp === 'undefined' || +exp === 0) {
			return Math[type](value);
		}
		value = +value;
		exp = +exp;
		if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
			return NaN;
		}
		value = value.toString().split('e');
		value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
		value = value.toString().split('e');
		return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
	}

	// Round to closest
	if (!Math.round10) {
		Math.round10 = function(value, exp) {
			return decimalAdjust('round', value, exp);
		};
	}
	// Round to down
	if (!Math.floor10) {
		Math.floor10 = function(value, exp) {
			return decimalAdjust('floor', value, exp);
		};
	}
	// Round to up
	if (!Math.ceil10) {
		Math.ceil10 = function(value, exp) {
			return decimalAdjust('ceil', value, exp);
		};
	}
})();


/**
 * prepare data to be used in Drop down
 * @param {array} items
 * @return {object}
 */
export const prepareDropdownData = items => {
	return items.map(item => ({"key": item.id, "text": item.name, "value": item.id}));
};

export function Clean(obj) {
	const propNames = Object.getOwnPropertyNames(obj);
	for (let i = 0; i < propNames.length; i++) {
		let propName = propNames[i];
		if (obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName];
		}
	}

	return obj;
}

export  function formatMoney1(amount, decimalCount = 0, decimal = ".", thousands = ",") {
	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
		console.log(e)
	}
}

/**
 * do a money format using intl lib
 * @param amount
 * @param currency
 * @param locale
 * @param decimalCount
 * @return {string}
 */
export function formatMoney(amount, currency, locale, decimalCount = 0) {
	return (new Intl.NumberFormat(locale, {
		"style": "currency",
		"currency": currency,
		"minimumFractionDigits": decimalCount
	})).format(amount)
}

/**
 * get currency symbol
 * @param {string} currency_code
 * @param {array} currencies
 * @return {string}
 */
export function currencySymbol(currency_code, currencies) {
	let currency = currency_code || "";
	const r = currencies.filter(c => c.currency_code.toLowerCase() === currency.toLowerCase());
	return (r.length > 0)? r[0].symbol : Config.currencySymbol;
}

/**
 * get full time zone name
 * @param {string} time_zone
 * @param {array} timezones
 * @return {string}
 */
export function getTimeZone(time_zone, timezones) {
	const r = timezones.filter(t => t.value.toLowerCase() === time_zone.toLowerCase());
	return (r.length > 0)? r[0].title : time_zone;
}

/**
 * do a number format using intl lib
 * @param amount
 * @param {string} locale
 * @param decimalCount
 * @return {string}
 */
export function formatNumber(amount, locale = "en-IN", decimalCount = 0) {
	return (new Intl.NumberFormat(locale, {
		"minimumFractionDigits": decimalCount
	})).format(amount)
}


/**
 * Check that provided attribute is digit
 * @param {float|string|number} num
 * @return {boolean}
 */
export function isDigit(num) {
	return !isEmpty(num) && !isNaN(Number(num));
}

/**
 * check user has internal type
 * @param {object} user
 * @return {boolean}
 */
export const isInternal = user => {
	return user.role && user.type?.toLowerCase() === "internal";
};

/**
 * verify that jwt token has admin rights
 * @param {object} user
 * @return {boolean}
 */
export const isAdmin = user => {
	return user.role?.toLowerCase() === "admin";
};

/**
 * get parsed jwt user
 * @param {object} u
 * @return {object}
 */
const getJWTUser = u => {
	const key = Object.keys(u).filter(k => ~k.indexOf("t1"));
	return u[key] || {};
};

/**
 * verify that jwt token has reporter rights
 * @param {object} u
 * @return {boolean}
 */
export const isReporter = user => {
	return user.role?.toLowerCase() === "reporter";
};

/**
 * parse jwt token
 * @param {string} token
 * @return {object}
 */
export const parseJwt = token => {
	let base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
};

/**
 * Check that value is empty
 * @param {float|string|number} num
 * @return {boolean}
 */
export function isEmpty(num) {
	return String(num).length < 1;
}

export function isInt(n){
	return isDigit(n) && n % 1 === 0;
}

/**
 * Check that value has the only numbers inside
 * @param value
 */
export function hasOnlyNumbers(value) {
	return !Boolean(~value.search(/\D/g))
}

/**
 * Check that value is a valid http link
 * @param {string} value
 * @returns {boolean}
 */
export function isValidHttpUrl(value) {
	let url;
	try {
		url = new URL(value);
	} catch (_) {
		return false;
	}
	return url.protocol === "http:" || url.protocol === "https:";
}

export function isFloat(n) {
	return isDigit(n) && n % 1 !== 0;
}

/**
 * Check that we met error for the key
 * @param {object} error
 * @param {string} error.dataPath
 * @param {string} key
 * @return {boolean}
 */
export function hasError(error, key) {
	return error && error.dataPath && Boolean(~error.dataPath.indexOf(key));
}

/**
 * Transform date format from javascript to API
 * @param {string} datetime
 * @param {string} date_format
 * @return {string}
 */
export function getApiDate(datetime, date_format="yyyy-MM-dd") {
	return lightFormat(new Date(Date.parse(datetime)), date_format);
}

/**
 * verify that server response is ok
 * @param {object} r
 */
export const isOk = r => {
	return r?.meta?.status === "ok";
};

/**
 * Return the date parsed from string returned by API.
 * @param {string} datetime
 * @return {Date}
 */
export function parseAPIDateTime(datetime) {
	return parseDate(datetime, "yyyy-MM-dd HH:mm", new Date());
}

/**
 * Verify that provided date time is after now()
 * @param {string} datetime
 * @returns {boolean}
 */
export function isApiDateTimeExpired(datetime) {
	const apiDate = parseDate(datetime, "yyyy-MM-dd HH:mm:ss", new Date());
	return isBefore(apiDate, new Date());
}

/**
 * Return the date parsed from string returned by API including timezone
 * @param {string} datetime
 * @param {string} timezone
 * @return {Date}
 */
export function parseAPIDateTimeWithTimeZone(datetime, timezone) {
	return utcToZonedTime(datetime, timezone);
}

/**
 * Return the formatted datetime string for API.
 * @param {Date} date
 * @return {string}
 */
export function formatAPIDateTime(date) {
	return lightFormat(date, "yyyy-MM-dd HH:mm");
}

/**
 * Return the date parsed from string returned by API.
 * @param {string} datetime
 * @return {Date}
 */
export function parseAPIDate(datetime) {
	return parseDate(datetime, "yyyy-MM-dd", new Date());
}

/**
 * Return the formatted date string for API.
 * @param {Date} date
 * @return {string}
 */
export function formatAPIDate(date) {
	return lightFormat(date, "yyyy-MM-dd");
}

/**
 * get checked segments
 * @param audiences
 * @return {object}
 */
export const getCheckedSegments = audiences => {
	let segments = {};
	const tree = node => {
		return node.map(item => {
			if(item.hasOwnProperty("checked") && item.checked) {
				if(!segments[item.category_slug]) {
					segments[item.category_slug] = [];
				}

				segments[item.category_slug].push(item.id);
			} else if(item.child) {
				item.child = tree(item.child);
			}

			return item;
		});
	};
	tree(audiences);

	return segments;
};

export function readablizeNumber(number) {
	const number_length = Math.log(number) * Math.LOG10E | 0;
	let decimals = number_length % 3;
	const e = 10 ** (number_length - decimals),
		s = ["", "K", "M", "B"],
		element = Math.floor(Math.log(number) / Math.log(1000));

	let result = number;
	switch(decimals) {
		case(0):
			decimals = 2;
			break;

		case(2):
		default:
			decimals = 0;
			break;
	}

	if(number >= 1000) {
		result = `${Number(Math.round10(number/e, -1*decimals)).toFixed(decimals)}${s[element]}`;
	}

	return result;
}

/**
 * Get utc date
 * @param {Date} local_date
 * @return {Date}
 */
export function getUTCDate(local_date) {
	let tmp = new Date(Date.UTC(local_date.getFullYear(), local_date.getMonth(), local_date.getDate(), 0, 0, 0));
	tmp.setHours(0);
	return tmp;
}

/**
 * Make valid time string from pieces
 * @param hour
 * @param minute
 * @param {boolean} full_format
 * @return {string}
 */
export function getApiTime(hour, minute, full_format=true) {
	let converted_hour = hour,
		converted_minute = (minute === null)? "" : minute.toString();

	converted_hour = (converted_hour < 10)? `0${converted_hour}` : converted_hour;
	converted_minute = (converted_minute < 10)? `0${converted_minute}` : converted_minute;

	return (full_format)? `${converted_hour}:${converted_minute}:00` : `${converted_hour}:${converted_minute}`;
}

/**
 * add leading zero
 * @param {number} num
 * @return {string}
 */
export function pad2(num) {
	return (num < 10 ? "0" : "") + num;
}

/**
 * get array of weekly days
 * @return {Array}
 */
export function getWeeklyDays() {
	return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
}

export const isNil = val => val == null;
export const isUndefined = val => val === undefined;

/**
 * convert code to symbol
 * @param {String} code
 * @return {String}
 */
export function currencyCodeToSymbol(code) {
	const currency_symbols = {
		'USD': '$', // US Dollar
		'EUR': '€', // Euro
		'CRC': '₡', // Costa Rican Colón
		'GBP': '£', // British Pound Sterling
		'ILS': '₪', // Israeli New Sheqel
		'INR': '₹', // Indian Rupee
		'JPY': '¥', // Japanese Yen
		'KRW': '₩', // South Korean Won
		'NGN': '₦', // Nigerian Naira
		'PHP': '₱', // Philippine Peso
		'PLN': 'zł', // Polish Zloty
		'PYG': '₲', // Paraguayan Guarani
		'THB': '฿', // Thai Baht
		'UAH': '₴', // Ukrainian Hryvnia
		'VND': '₫', // Vietnamese Dong,
		'CAD': '$', // Canadian Dollar
		'AED': 'د.إ', // UAE Dirham
		'ARS': '$', // Argentine Peso
		'AUD': '$', // Australian Dollar
		'BGN': 'лв', // Bulgarian Lev
		'BRL': 'R$', // Brazilian Real
		'CHF': '₣', // Swiss Franc
		'CLP': '$', // Chilean Peso
		'CNY': '¥', // Yuan
		'COP': '$', // Colombian Peso
		'CZK': 'Kč', // Czech Koruna
		'DKK': 'kr', // Danish Krone
		'EGP': '£', // Egyptian Pound
		'FJD': '$', // Fiji Dollar
		'HKD': '$', // Hong Kong Dollar
		'HUF': 'Ft', // Forint
		'IDR': 'Rp', // Rupiah
		'ISK': 'Kr', // Iceland Krona
		'JMD': '$', // Jamaican Dollar
		'JOD': 'د.ا', // Jordanian Dinar
		'KWD': 'د.ك', // Kuwaiti Dinar
		'MAD': 'د.م.', // Moroccan Dirham
		'MXN': '$', // Mexican Peso
		'MYR': 'RM', // Malaysian Ringgit
		'NOK': 'kr', // Norwegian Krone
		'NZD': '$', // New Zealand Dollar
		'PEN': 'S/.', // Nuevo Sol
		'PKR': '₨', // Pakistan Rupee
		'QAR': 'ر.ق', // Qatari Rial
		'RUB': 'р.', // Russian Ruble
		'SAR': 'ر.س', // Saudi Riyal
		'SEK': 'kr', // Swedish Krona
		'SGD': '$', // Singapore Dollar
		'TRY': '₤', // Turkish Lira
		'TWD': '$', // Taiwan Dollar
		'VEB': 'Bs.', // Venezuelan Bolivar
		'ZAR': 'R', // Rand

	};

	return (code)? currency_symbols[code.toUpperCase()] : "";
}

/**
 * Rename a key in the object
 * @param {object} item
 * @param {string} keyFrom
 * @param {string} keyTo
 */
export function renameKey(item, keyFrom, keyTo) {
	if(keyFrom === keyTo) {
		return;
	}

	if(item.hasOwnProperty(keyFrom)) {
		item[keyTo] = item[keyFrom];
		delete item[keyFrom];
	}
}

/**
 * get date next to closest with interval 15 min
 * @param {Date} date
 * @return {Date}
 */
export function getClosestTo15Date(date) {
	const minutes = date.getMinutes();
	return add(date, {"minutes": Math.abs(date.getMinutes() - getClosestTo15Delta(minutes))});
}

/**
 * get tomorrow
 * @return {Date}
 */
export function getTomorrowDate() {
	return add(new Date(), {"days": 1});
}

/**
 * get closest delta number to 15
 * @param n
 * @return {number}
 */
export function getClosestTo15Delta(n) {
	return Math.ceil(n / 15) * 15;
}

export const campaignHasPGType = (item) => item.hasOwnProperty("type") && item.type.toLowerCase() === "pg";

/**
 * Clone `array` and move the item to a given position.
 * @param {*[]} array
 * @param {number} from
 * @param {number} to
 * @returns {*[]} modified array
 */
export const arrayMove = (array, from, to) => {
	array = [...array];

	if (from >= 0 && from < array.length) {
		const endIndex = to < 0 ? array.length + to : to;
		const [item] = array.splice(from, 1);
		array.splice(endIndex, 0, item);
	}
	return array;
};

/**
 * capitalize 1st letter in string
 * @param {string} value
 * @return {string}
 */
export const capitalizeFirstLetter = value => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * rebuild public_client variable into human readable format
 * replace '_' with ' ', update all words with capital first letter
 * @param {string} value
 * @returns {string}
 */
export const getReadableClientName = value => {
	return value.replaceAll("_", " ").split(" ").map(word => capitalizeFirstLetter(word)).join(" ");
};

/**
 * Extracts error message from an Error or server response.
 * @param {Error|object} e error object to convert
 * @returns {string|null}
 */
export function errorToMessage(e) {
	if (e?.error?.message) return e.error.message;
	if (e.message) return e.message;
	return null;
}

/**
 * format campaign name
 * @param {string} campaign_type
 * @return {string}
 */
export const getCampaignType = campaign_type => {
	return `${campaign_type.toUpperCase()}/PMP/OPEN`;
};

/**
 * generate campaign type based on LD flags
 * @param pmp
 * @param audience
 * @param open_supply
 * @param lang
 * @return {string}
 */
export const getCampaignTypeBasedOnLD = (pmp, audience, open_supply, lang) => {
	const supplyLabels = {
		"pmp": "PMP",
		"audience": lang["campaign"]["supply_type"],
		"open_supply": "Open Supply"
	};

	/**
	 * generate supply text based on LD flags
	 * @return {string}
	 */
	const getOptionText = () => {
		const option = [];
		if(pmp) {
			option.push(supplyLabels["pmp"]);
		}

		if(audience) {
			option.push(supplyLabels["audience"]);
		}

		if(open_supply) {
			option.push(supplyLabels["open_supply"]);
		}

		return option.join(" / ");
	}

	return getOptionText();
};

/**
 * check that property of the object exists and its not empty
 * @param {object} data
 * @param {string} property
 * @returns {boolean}
 */
export const isExists = (data, property) => {
	return data.hasOwnProperty(property) && data[property].toString().length > 0;
};

/**
 * Generate common pixel link
 * @param {object} values
 * @return {string}
 */
export const generatePixelSnippet = values => {
	if(!values) {
		return "";
	}

	let code_snippet_link = `<script async src='//pixel.mathtag.com/event/js?mt_id=${values.id}&mt_adid=${values.advertiser_id}&mt_exem=&mt_excl=&s1=&s2=&v1=&v2='></script>`;

	if(values?.revenue_enabled) {
		const revenue = values.revenue.toLowerCase();
		if(values.revenue_type === RevenueType.FIXED) {
			code_snippet_link = `<script async src='//pixel.mathtag.com/event/js?mt_id=${values.id}&mt_adid=${values.advertiser_id}&mt_exem=&mt_excl=&s1=&s2=&v1=&v2='></script>`;
		} else if(values.revenue_type === RevenueType.DYNAMIC) {
			code_snippet_link = `<script async src='//pixel.mathtag.com/event/js?mt_id=${values.id}&mt_adid=${values.advertiser_id}&mt_exem=&mt_excl=&s1=&s2=&v1=&v2='></script>`;
		}
	}

	return code_snippet_link;
}

/**
 * Format bytes as human-readable text.
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si=true, dp=1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + ' B';
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10**dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


	return bytes.toFixed(dp) + ' ' + units[u];
}
