import React, {useReducer, useState} from "react";
import {
	Divider,
	Form,
	Header,
	Segment,
	Button,
	Grid,
	Message
} from "semantic-ui-react";
import {NavigationButtonDiv} from "./navigation_buttons";
import {Steps} from "../../../../models/enum/strategy";
import StrategyManageContext from "../context";
import StrategiesService from "../../../../services/strategy";
import {isDigit, targetHasChildren} from "../../../../libs/common_utils";
import {StrategySaveComponent} from "./save_modal";
import {actions as trgtAction} from "./reducers/technology";
import TechnologyContext from "./contexts/technology";
import TargetValuesControl from "./reducers/technology";
import {targetValuesControlReducer} from "./reducers/technology";
import {resetInitTreeFlag} from "./reducers/audiences";
import {DimensionCodes} from "../../../../models/enum/dimension_codes";
import HttpConnect from "../../../../libs/http_connect";
import FilterControl from "./filters/index";
import Filter from "./models/filter";
import {strategy_technology_filters} from "./fixtures";
import { useIntl } from "react-intl";

const save_state = [{
	"step": Steps.MARKETING_OBJECTIVES,
	"status": "loading",
	"messageId": "STEP_MARKETING_OBJECTIVES",
}, {
	"step": Steps.SUPPLY,
	"status": "loading",
	"messageId": "STEP_SUPPLY",
}, {
	"step": Steps.CREATIVES,
	"status": "loading",
	"messageId": "STEP_CREATIVES",
}, {
	"step": Steps.AUDIENCES,
	"status": "loading",
	"messageId": "STEP_AUDIENCES",
}, {
	"step": Steps.DAYPARTS,
	"status": "loading",
	"messageId": "STEP_DAYPARTS",
}, {
	"step": Steps.TECHNOLOGY,
	"status": "loading",
	"messageId": "STEP_TECHNOLOGY",
}];

const initialState = {
	"items": [],
	"filter": new Filter(DimensionCodes.BSER),
	"searchResults": null
};

const included = new Map();
const opened = new Map();

export const TechnologyStep = () => {
	const context = React.useContext(StrategyManageContext);
	const intl = useIntl();

	const service = context.services.current.get("strategies");
	const service_strategy = context.services.current.get("strategies_common");

	const [loading, setLoading] = React.useState(false);
	const [serverError, setServerError] = useState("");
	const [strategySave, setStrategySave] = useState(false);
	const is_t1_edit = (service instanceof StrategiesService);
	const [values, dispatch] = useReducer(targetValuesControlReducer, []);
	const [state, setState] = React.useState(initialState);
	const [_, redraw] = React.useState();

	const technology_has_been_blocked = React.useRef(false);
	const data_exists_in_db = React.useRef(false);
	let _isMounted = React.useRef(true);

	const strategy_id = parseInt(context.strategy_id || 0, 10);

	/**
	 * check that we have saved something
	 * @param {object} d
	 * @return {boolean}
	 */
	const savedDataExists = d => {
		return Object.values(d).reduce((counter, node) => {
			return counter + node.length;
		}, 0) > 0;
	};

	React.useEffect(() => {
		technology_has_been_blocked.current = false;
		setLoading(true);
		(async() => {
			try {
				const saved = await service.get_technology(strategy_id);

				/** @namespace saved.data **/
				const d = saved.data;

				if(savedDataExists(d)) {
					data_exists_in_db.current = true;
					d.browser_ids.forEach(n => {
						included.set(n.id, {"id": n.id, "full_path": n.title, "dimension_code": DimensionCodes.BSER});
					});

					d.device_ids.forEach(n => {
						included.set(n.id, {"id": n.id, "full_path": n.title, "dimension_code": DimensionCodes.DVCE});
					});

					d.device_model_ids.forEach(n => {
						included.set(n.id, {"id": n.id, "full_path": n.title, "dimension_code": DimensionCodes.NMDM});
					});

					d.inventory_type_ids.forEach(n => {
						included.set(n.id, {"id": n.id, "full_path": n.title, "dimension_code": DimensionCodes.INVT});
					});

					d.os_version_ids.forEach(n => {
						included.set(n.id, {"id": n.id, "full_path": n.title, "dimension_code": DimensionCodes.NMOS});
					});

					fillSelectedDiv("included_id", included);
				}
				/*
				initialData = [...r.data].map(n => {
					n.parent_id = 0;
					n.has_children = true;
					return n;
				});
				*/
				dispatch({"type": trgtAction.INIT, "data": []});
			} catch (e) {
				technology_has_been_blocked.current = Boolean(~e.error.message.toLowerCase().search(/have been changed outside/));
				setServerError(e.error.message);
			} finally {
				setLoading(false);
			}
		})();

		return () => {
			resetInitTreeFlag();
			opened.clear();
			included.clear();
			_isMounted.current = false;
			initialState.filter.reset();
		};
	}, []);

	/**
	 * get list of ids from saved array
	 * @param {Array} saved
	 * @param {String} code
	 * @return {Array<number>}
	 */
	const getIDsByDimension = (saved, code) => {
		return saved.filter(x => x.dimension_code === code && isDigit(x.id)).map(x => x.id);
	};

	/**
	 * Load technologies from backend.
	 * @param {object} filter
	 * @param {string} filter.filter_type
	 * @param {string} filter.filter_query
	 */
	const performSearchTechnologies = filter => {
		HttpConnect.cancelRequest();

		const query = filter.filter_query.trim();
		if (query === "") {
			setState({
				...state,
				"searchResults": null
			});
			return;
		}

		(async () => {
			setLoading(true);

			try {
				const r = await service_strategy.get_data_by_dimension_code(filter.filter_type, 0, filter.filter_query);
				/** @namespace r.data **/

				setState({
					...state,
					"searchResults": r.data.map(({id, title, is_targetable}) => ({
						id,
						title,
						"checkable": Boolean(is_targetable),
						"full_path": title,
						"type": null
					}))
				});
			} catch (e) {
				console.error("[performSearchTechnology]", e);
			} finally {
				if(_isMounted.current) {
					setLoading(false);
				}
			}
		})();
	};

	/**
	 * Save date in the local db
	 * @return {Promise<void>}
	 */
	const handleSubmit = async () => {
		// clear error first
		setServerError("");
		setLoading(true);
		try {
			const included_array = Array.from(included.values());
			const data = {
				"browser_ids": getIDsByDimension(included_array, DimensionCodes.BSER),
				"device_ids": getIDsByDimension(included_array, DimensionCodes.DVCE),
				"device_model_ids": getIDsByDimension(included_array, DimensionCodes.NMDM),
				"inventory_type_ids": getIDsByDimension(included_array, DimensionCodes.INVT),
				"os_version_ids": getIDsByDimension(included_array, DimensionCodes.NMOS)
			};

			if(data_exists_in_db.current || is_t1_edit) {
				await service.update_technology(strategy_id, data);
			} else {
				await service.create_technology(strategy_id, data);
				data_exists_in_db.current = true;
			}

			if(is_t1_edit) {
				context.getBack(true);
			} else {
				setStrategySave(true);
			}
		} catch(e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setLoading(false);
			}
		}
	};

	/**
	 * dispatch switch audience
	 * @param e
	 * @param id
	 * @param {object} [outOfTreeItem] object to use instead of searching the tree
	 */
	const switchTarget = (e, id, outOfTreeItem) => {
		if (outOfTreeItem) {
			// Item was found using search and might not be in tree yet,
			// so we add a hint of its value object instead:
			included.switch(id, {...outOfTreeItem});
			redraw(Date.now());
		} else {
			let found = false;
			const tree = node => {
				return node.forEach(item => {
					if(found) {
						return;
					}

					if(item.id === id) {
						if(included.has(id)) {
							included.delete(id);
						} else {
							included.set(id, {...item});
						}
						found = true;
					} else if(item.child) {
						tree(item.child)
					}
				});
			};

			tree([...values]);
		}

		// e.target.checked = false;


		// do rebuild checkbox tree
		let nodes = document.querySelectorAll(".custom-checkbox > input[type='checkbox']:checked");

		nodes.forEach(n => {
			const id = (!isNaN(n.value))? parseInt(n.value , 10) : n.value;
			if(!included.has(id)) {
				setTimeout(() => {
					n.checked = false;
				});
			}
		});

		Array.from(included.keys()).forEach(i => {
			const node = document.getElementById(`target_${i}`);
			if(node) {
				setTimeout(() => {
					node.checked = true;
				});
			}
		});

		fillSelectedDiv("included_id", included);
		return true;
	};

	/**
	 * reset included map
	 */
	const resetIncluded = () => {
		included.clear();
		fillSelectedDiv("included_id", included);
		document.querySelectorAll(".custom-checkbox > input[type='checkbox']:checked").forEach(node => {
			node.checked = false;
		});
	};

	/**
	 * Put data into included divs
	 * @param {String} div_id
	 * @param {Map} nodes
	 */
	const fillSelectedDiv = (div_id, nodes) => {
		const element = document.getElementById(div_id);
		element.innerHTML = "";

		let html = "";
		nodes.forEach(x => {
			html += `<a class="ui label">${x.full_path}<i aria-hidden="true" class="delete remove_icon icon" data-id="${x.id}"> </i></a>`;
		});

		element.innerHTML = html;
		// attach event listeners
		Array.from(element.getElementsByClassName("remove_icon")).forEach(node => {
			node.onclick = function(e) {
				let id = e.target.getAttribute("data-id");
				id = (isDigit(id))? parseInt(id, 10) : id;

				const checkbox = document.getElementById(`target_${id}`);
				if(included.has(id)) {
					included.delete(id);

					if(checkbox) {
						checkbox.checked = false;
					}
				}

				e.target.parentNode.remove();
			};
		});
	};

	/**
	 * dispatch switch visibility
	 * @param e
	 * @param {object} target
	 */
	const switchVisibility = (e, target) => {
		const id = target.id;

		if(targetHasChildren(target)) {
			// target contains children and they are not loaded yet
			setLoading(true);

			(async () => {
				try {
					const r = await service_strategy.get_data_by_dimension_code(target.dimension_code, target.id);
					/** @namespace r.data **/

					const data = [...r.data].map(n => {
						n.parent_id = target.id;
						n.type = target.type;
						n.dimension_code = target.dimension_code;
						n.has_children = Boolean(n.child_count > 0);
						return n;
					});

					dispatch({"type": trgtAction.APPEND, "data": data, "root": target});
				} catch (e) {
					console.error(e);
					setServerError(e.error.message);
				} finally {
					setLoading(false);
				}
			})();
		} else {
			e.parentNode.classList.toggle("opened");
			if(e.parentNode.classList.contains("opened")) {
				opened.set(id, "opened ul-container");
			} else {
				opened.set(id, "ul-container");
			}
		}
	};

	return <Segment basic>
		<Message
			style={{ "marginTop": "10px" }}
			error
			hidden={!serverError}
			size="tiny"
			content={serverError}
		/>
		{strategySave && <StrategySaveComponent
			save_state={save_state}
			strategy_id={strategy_id}
			campaign_id={context.campaign.id}
			resetModal={() => setStrategySave(false)}
			navigate_back={() => context.strategyCreated()}
			navigation={context.stepNavigation}
		/>}
		<Form id="technology_form" loading={loading} onSubmit={handleSubmit}>
			<FilterControl filter={initialState.filter}
										 onChange={performSearchTechnologies}
										 loading={loading}
										 is_visible={technology_has_been_blocked.current}
										 title={intl.formatMessage({
											 id: "LABEL_TECHNOLOGY",
											 defaultMessage: "Technology",
										 })}
										 options={strategy_technology_filters(intl)} />
			<Grid columns={2} stackable>
				<Grid.Row>
					<Grid.Column>
						<Header as="h3">&nbsp;</Header>
						<Segment style={{"overflowY": "scroll", "height": 400, "paddingTop": 0}} id="nested-targets">
							<TechnologyContext.Provider value={{
								switchTarget, switchVisibility, included, opened,
								"campaign": context.campaign
							}}>
								{state.searchResults === null &&
									<div className="nested-targets">
										<TargetValuesControl values={values} />
									</div>}
								{state.searchResults !== null && <div className="search-results-list">
									{state.searchResults.length ? (
										<ul>
											{state.searchResults.map(result => (
												<li className="field custom-checkbox" key={result.id}>
													<input
														type="checkbox"
														id={`target_${result.id}`}
														value={result.id}
														checked={included.has(result.id)}
														disabled={!result.checkable}
														style={{"opacity": 0}}
														className={!result.checkable? "invisible" : ""}
														onChange={(e) =>
															switchTarget(e, result.id, result)
														}
													/>
													<label
														className="target-label"
														htmlFor={`target_${result.id}`}
													>
														{result.full_path}
													</label>
												</li>
											))}
										</ul>
									) : (
										<p style={{"padding": "15px", "fontWeight": "bolder"}}>
											{intl.formatMessage({
												id: "EMPTY_SEARCH_RESULTS",
												defaultMessage: "No results found",
											})}
										</p>
									)}
								</div>}
							</TechnologyContext.Provider>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Header as="h3">
							{intl.formatMessage({
								id: "LABEL_INCLUDED_TECHNOLOGIES",
								defaultMessage: "Included technologies",
							})}{" "}
							<Button compact size='mini' className="btn-clear" type="button" onClick={resetIncluded}>
								{intl.formatMessage({
									id: "BTN_CLEAR",
									defaultMessage: "Clear",
								})}
							</Button>
						</Header>
						<Segment id="included_id" className="assigned-creatives" style={{"overflowY": "scroll", "height": 400 }}>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<NavigationButtonDiv
				loading={loading}
				step={Steps.TECHNOLOGY}
				isPG={context.campaign.is_pg}
				onSave={() => {}}
				is_t1_edit={is_t1_edit}
				skip_save_buttons={technology_has_been_blocked.current}
				onBackClick={() => context.stepNavigation.backToDayparts(strategy_id)}
				onContinueClick={() => context.getBack(true)}
				onCancelClick={context.getBack}
			/>
		</Form>
	</Segment>;
};
