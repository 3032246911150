import React, { useState } from "react";
import PropTypes from "prop-types";

import {
	Button,
	Divider,
	Dropdown,
	Form,
	Input,
	Loader,
	Radio,
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import union from "lodash/union";

import { useDebounce, useForm } from "../../../libs/component_utils";
import HttpConnect from "../../../libs/http_connect";
import AgenciesService from "../../../services/agencies";

import MillerPicker from "./miller-picker";
import T1Service from "../../../services/t1";
import { user_roles } from "../fixtures";
import PasswordsService from "../../../services/passwords";
import { Config } from "../../../config/api";
import { errorToMessage } from "../../../libs/common_utils";

/**
 * User create page component
 * @return {*}
 * @constructor
 */
const UserForm = ({
	initialData,
	loading,
	onSubmit,
	onCancel,
	initialIndeterminateAgencies = [],
	formType = "create",
	submitText = "Upload Device ID",
}) => {
	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const UserForm = async () => {
		await onSubmit(values);
	};

	const intl = useIntl();

	const {
		values,
		updateValues,
		errors,
		onChange,
		onSubmit: handleSubmit,
		setErrors,
	} = useForm(UserForm, initialData, () => {
		const errors = {};

		if (
			formType !== "profile" &&
			!values.agency_ids.length &&
			!values.advertiser_ids.length
		) {
			errors.agency_ids = intl.formatMessage({
				id: "ERROR_EMPTY_ENTITIES",
				defaultMessage: "Please select at least one agency or advertiser.",
			});
		}

		return errors;
	});

	const services = React.useRef(
		new Map([["passwords", new PasswordsService()]])
	);
	const [passwordLoading, setPasswordLoading] = useState();
	const [passwordError, setPasswordError] = useState();
	const [passwordFinal, setPasswordFinal] = useState();
	const onResetPassword = async () => {
		setPasswordLoading(true);
		try {
			const response = await services.current.get("passwords").create({
				email: values.username,
			});
			if (response.meta.status === "Error") {
				throw response;
			} else {
				setPasswordFinal(
					intl.formatMessage({
						id: "BODY_RESET_PWD_SENT",
						defaultMessage: "We’ve just sent you an email to reset your password.",
					})
				);
			}
		} catch (e) {
			setPasswordError(`Error: ${e.message || e.error.message}`);
		} finally {
			setPasswordLoading(false);
		}
	};

	return (
		<Form
			onSubmit={handleSubmit}
			size="small"
			loading={loading}
			noValidate
			error={!!Object.keys(errors).length}
			autoComplete="off"
		>
			{formType !== "profile" && (
				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_STATUS",
							defaultMessage: "Status",
						})}
					</label>
					<Radio
						name="status"
						label={intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
						})}
						value={1}
						checked={!!values.status}
						onChange={onChange}
					/>
					<Radio
						style={{ "marginLeft": "15px" }}
						name="status"
						label={intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
						})}
						value={0}
						checked={!values.status}
						onChange={onChange}
					/>
				</Form.Field>
			)}

			<Form.Field inline error={errors.hasOwnProperty("first_name")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_FIRST_NAME",
						defaultMessage: "First name",
					})}
				</label>
				<Input
					name="first_name"
					required
					minLength={1}
					maxLength={32}
					defaultValue={values.first_name}
					onBlur={onChange}
				/>
				<div className="custom-error">{errors["first_name"]}</div>
			</Form.Field>

			<Form.Field inline error={errors.hasOwnProperty("last_name")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_LAST_NAME",
						defaultMessage: "Last name",
					})}
				</label>
				<Input
					name="last_name"
					required
					minLength={1}
					maxLength={32}
					defaultValue={values.last_name}
					onBlur={onChange}
				/>
				<div className="custom-error">{errors["last_name"]}</div>
			</Form.Field>

			<Form.Field inline error={errors.hasOwnProperty("title")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_TITLE",
						defaultMessage: "Title",
					})}
				</label>
				<Input
					name="title"
					required
					minLength={1}
					maxLength={64}
					defaultValue={values.title}
					onBlur={onChange}
				/>
				<div className="custom-error">{errors["title"]}</div>
			</Form.Field>

			<Form.Field inline error={errors.hasOwnProperty("phone")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_PHONE",
						defaultMessage: "Phone",
					})}
				</label>
				<Input
					name="phone"
					type="tel"
					required
					minLength={8}
					maxLength={24}
					pattern="^[\d ()+-]+$"
					title={intl.formatMessage({
						id: "ERROR_PHONE_PATTERN",
						defaultMessage: "Phone may only include digits, spaces, +, -, ()",
					})}
					defaultValue={values.phone}
					onBlur={onChange}
				/>
				<div className="custom-error">{errors["phone"]}</div>
			</Form.Field>

			{formType === "create" ? (
				<Form.Field inline error={errors.hasOwnProperty("username")} required>
					<label>
						{intl.formatMessage({
							id: "LABEL_EMAIL",
							defaultMessage: "Email",
						})}
					</label>
					<Input
						name="username"
						type="email"
						required
						minLength={1}
						maxLength={128}
						defaultValue={values.username}
						onBlur={onChange}
					/>
					<span style={{ marginLeft: 20, color: "rgba(0, 0, 0, .6)" }}>
						{intl.formatMessage({
							id: "HINT_EMAIL",
							defaultMessage: "Email is the username",
						})}
					</span>
					<div className="custom-error">{errors["username"]}</div>
				</Form.Field>
			) : (
				<Form.Field inline required>
					<label>
						{intl.formatMessage({
							id: "LABEL_EMAIL",
							defaultMessage: "Email",
						})}
					</label>
					<span>{values.username}</span>
				</Form.Field>
			)}

			{formType !== "create" && (
				<Form.Field inline error={Boolean(passwordError)} required>
					<label>
						{intl.formatMessage({
							id: "LABEL_PASSWORD",
							defaultMessage: "Password",
						})}
					</label>
					{!passwordFinal ? (
						<>
							<button
								type="button"
								disabled={passwordLoading}
								className="pseudo-link"
								onClick={onResetPassword}
							>
								{intl.formatMessage({
									id: "BTN_RESET_PWD_ADMIN",
									defaultMessage: "Click to send password reset email",
								})}
							</button>
							<Loader
								size="mini"
								active={passwordLoading}
								inline
								style={{ marginLeft: 7, verticalAlign: "text-bottom" }}
							/>
						</>
					) : (
						<span>{passwordFinal}</span>
					)}
					<div className="custom-error">{passwordError}</div>
				</Form.Field>
			)}

			{formType !== "profile" && (
				<Form.Field inline error={errors.hasOwnProperty("role")} required>
					<label>
						{intl.formatMessage({
							id: "LABEL_USER_ROLE",
							defaultMessage: "User Role",
						})}
					</label>

					<Dropdown
						required
						selection
						options={Object.entries(user_roles(intl)).map(([value, text]) => ({
							key: value,
							text,
							value,
						}))}
						// loading={pageData.agenciesLoading}
						placeholder={intl.formatMessage({
							id: "HINT_USER_ROLE",
							defaultMessage: "Select role",
						})}
						name="role"
						value={values.role}
						onChange={onChange}
					/>
					<div className="custom-error">{errors["role"]}</div>
				</Form.Field>
			)}

			{formType !== "profile" && (
				<Form.Field
					inline
					required
					error={Boolean(errors["agency_ids"] || errors["advertiser_ids"])}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_ENTITIES",
							defaultMessage: "Entities",
						})}
					</label>

					<EntityPicker
						values={values}
						// these will ge passed from backend on edit page, always empty on create page:
						initialIndeterminateAgencies={initialIndeterminateAgencies}
						onChange={updateValues}
						onError={(error) =>
							setErrors({
								...errors,
								"agency_ids": errorToMessage(error) || "Unknown error fetching data from server",
							})
						}
					/>
					<div className="custom-error">
						{errors["agency_ids"] || errors["advertiser_ids"]}
					</div>
				</Form.Field>
			)}

			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<Form.Field align="right">
				<Button size="tiny" type="button" onClick={onCancel}>
					{intl.formatMessage({
						id: "BTN_CANCEL",
						defaultMessage: "Cancel",
					})}
				</Button>
				<Button size="tiny" color="green" type="submit">
					{submitText}
				</Button>
			</Form.Field>
		</Form>
	);
};
UserForm.propTypes = {
	"formType": PropTypes.string,
};

export default UserForm;

const EntityPicker = ({
	values,
	initialIndeterminateAgencies,
	onChange,
	onError,
}) => {
	const agenciesService = new AgenciesService();
	const t1Service = new T1Service();

	const intl = useIntl();
	const [agenciesLoading, setAgenciesLoading] = useState(true);
	const [agencies, setAgencies] = useState([]);
	const [advertisersLoading, setAdvertisersLoading] = useState(false);
	const [advertisers, setAdvertisers] = useState([]);

	const [indeterminateAgencies, setIndeterminateAgencies] = useState(
		new Set(initialIndeterminateAgencies)
	);
	const [activeAgency, setActiveAgency] = useState(null);

	const initialAgencies = React.useRef();

	const fetchInitialData = async () => {
		try {
			const response = await agenciesService.fetchAvailableAgencies();

			initialAgencies.current = response.data.map(({ id, title }) => ({
				text: title,
				value: id,
			}));

			setAgencies(initialAgencies.current);
		} catch (e) {
			onError(e);
		} finally {
			setAgenciesLoading(false);
		}
	};

	React.useEffect(() => {
		fetchInitialData();
	}, []);

	const handleSelectAgency = async (selectedValue) => {
		HttpConnect.cancelRequest();
		setActiveAgency(selectedValue);
		setAdvertisersLoading(true);
		try {
			const response = await t1Service.advertisers(selectedValue);
			setAdvertisers(
				response.data.map(({ id, title }) => ({
					"key": id,
					"text": title,
					"value": id,
				}))
			);
			if (values.agency_ids.includes(selectedValue)) {
				onChange({
					advertiser_ids: union(
						values.advertiser_ids,
						response.data.map(({ id }) => id)
					),
				});
			}
		} catch (e) {
			onError(e);
		} finally {
			setAdvertisersLoading(false);
		}
	};

	const handleChangeAgencies = (nextValue) => {
		if (!activeAgency) {
			return onChange({ agency_ids: nextValue });
		}

		if (!nextValue.length && values.agency_ids.length >= 2) {
			// all agencies have been unchecked by master checkbox, not one by one
			setIndeterminateAgencies(new Set());
			return onChange({ agency_ids: [], advertiser_ids: [] });
		}

		const prevValueSet = new Set(values.agency_ids);
		const nextValueSet = new Set(nextValue);
		let advertiser_ids = values.advertiser_ids;

		if (prevValueSet.has(activeAgency) && !nextValueSet.has(activeAgency)) {
			// active agency has been unchecked
			const advertiserValuesSet = new Set(
				advertisers.map(({ value }) => value)
			);
			advertiser_ids = advertiser_ids.filter(
				(id) => !advertiserValuesSet.has(id)
			);
			setIndeterminateAgencies((indeterminateAgencies) => {
				const nextVal = new Set(indeterminateAgencies);
				nextVal.delete(activeAgency);
				return nextVal;
			});
		}

		if (!prevValueSet.has(activeAgency) && nextValueSet.has(activeAgency)) {
			// active agency has been checked
			const advertiserValues = advertisers.map(({ value }) => value);
			advertiser_ids = union(advertiser_ids, advertiserValues);
		}

		onChange({
			agency_ids: nextValue,
			advertiser_ids,
		});
	};

	const [agencySearchQuery, setAgencySearchQuery] = useState("");

	const performAgencySearch = (query) => {
		(async () => {
			if (query.trim() === "") {
				setAgencies(initialAgencies.current);
				return;
			}

			setAgenciesLoading(true);
			try {
				const response = await agenciesService.fetchAvailableAgencies(
					query.trim()
				);
				setAgenciesLoading(false);
				setAgencies(
					response.data.map(({ id, title }) => ({
						text: title,
						value: id,
					}))
				);
			} catch (e) {
				onError(e);
			}
		})();
	};

	const performAgencySearchDebounced = useDebounce(
		Config.search_debounce_delay,
		performAgencySearch
	);

	const handleChangeAdvertisers = (value) => {
		const advertiserValues = advertisers.map(({ value }) => value);
		const valueSet = new Set(value);
		let agency_ids = values.agency_ids;

		if (advertiserValues.every((v) => valueSet.has(v))) {
			agency_ids = [...agency_ids, activeAgency];
		} else {
			agency_ids = agency_ids.filter((id) => id !== activeAgency);
		}

		if (!advertiserValues.some((v) => valueSet.has(v))) {
			const nextVal = new Set(indeterminateAgencies);
			nextVal.delete(activeAgency);
			setIndeterminateAgencies(nextVal);
		} else if (!indeterminateAgencies.has(activeAgency)) {
			setIndeterminateAgencies(
				new Set(indeterminateAgencies).add(activeAgency)
			);
		}
		onChange({
			advertiser_ids: value,
			agency_ids,
		});
	};

	return (
		<MillerPicker disabled={agenciesLoading || advertisersLoading}>
			<MillerPicker.Column
				id="agencies_column"
				title={intl.formatMessage({
					id: "LABEL_AGENCIES",
					defaultMessage: "Agencies",
				})}
				loading={agenciesLoading}
				selection
				items={agencies}
				value={values.agency_ids}
				indeterminate={indeterminateAgencies}
				onChange={handleChangeAgencies}
				search={true}
				searchQuery={agencySearchQuery}
				searchPlaceholder={intl.formatMessage({
					id: "HINT_SEARCH",
					defaultMessage: "Search",
				})}
				onSearchChange={(searchQuery) => {
					setAgencySearchQuery(searchQuery);
					performAgencySearchDebounced(searchQuery);
				}}
				activeItem={activeAgency}
				onSelect={handleSelectAgency}
			/>

			{!activeAgency ? null : (
				<MillerPicker.Column
					id="advertisers_column"
					title={intl.formatMessage({
						id: "LABEL_ADVERTISERS",
						defaultMessage: "Advertisers",
					})}
					loading={advertisersLoading}
					items={advertisers}
					value={values.advertiser_ids}
					onChange={handleChangeAdvertisers}
				/>
			)}
		</MillerPicker>
	);
};
