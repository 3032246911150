import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Divider, Header, Segment } from "semantic-ui-react";

import SiteListForm from "./form";

const parentUrl = "/inventory";
const initialData = {
	title: "",
	status: true,
	restriction: "allow",
};

/**
 * SiteList create page component
 * @param history
 * @return {*}
 * @constructor
 */
const SiteListCreatePage = ({ history }) => {
	const intl = useIntl();
	const handleCancel = useCallback(async () => {
		history.push(parentUrl);
	}, [history]);

	/**
	 * Handle create form submit
	 * @returns {void}
	 */
	const handleSubmit = useCallback(async () => {
		history.push(parentUrl, { action: "created", type: "site-list" });
	}, [history]);

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_CREATE_SITE_LIST",
					defaultMessage: "Create Site List",
				})}
			</Header>
			<Divider />
			<SiteListForm
				initialData={initialData}
				submitButtonLabel={intl.formatMessage({
					id: "BTN_CREATE_SITE_LIST",
					defaultMessage: "Create Site List",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
		</Segment>
	);
};

export default SiteListCreatePage;
