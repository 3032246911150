import AbstractService from "../abstract.service";

export default class CreativesService extends AbstractService {
	static URI_STATES = new Map([
		["creatives.data", "/creatives-data"],
		["creatives.list_by_agency", "/list-creatives/{0}"],
		["creatives.concepts_by_agency", "/creatives/{0}"],
		["creatives.get", "/creative/{0}"],
		["creatives.put", "/creative/{0}"],
		["creatives.post", "/creative"],
		["creatives.video.post", "/video-creative"],
		["creatives.video.put", "/video-creative/{0}"],
		["creatives.video.get", "/video-creative/{0}"],
		["creatives.display.post", "/display-creative"],
		["creatives.display.put", "/display-creative/{0}"],
		["creatives.display.get", "/display-creative/{0}"],
		["onsite_creatives.list_by_agency", "/build-creatives/{0}"],
		["onsite_creatives.get_list", "/build-creatives"],
		["onsite_creatives.get_creative", "/build-creative/{0}"],
		["onsite_creatives.put_creative", "/build-creative/{0}/review"],
		["onsite_creatives.post", "/build-creative"],
		["onsite_creatives.get_pending_amount", "/pending-creatives"]
	]);

	/**
	 * post video-creative
	 * @return {Promise<>}
	 */
	async get_pending_amount() {
		return this.api_server.get(CreativesService.URI_STATES.get("onsite_creatives.get_pending_amount"));
	}

	/**
	 * update local creative
	 * @param {number} creative_id
	 * @param {object} params
	 * @returns {Promise<unknown>}
	 */
	async update_local(creative_id, params) {
		return this.api_server.post(CreativesService.URI_STATES.get("onsite_creatives.put_creative").format(creative_id), params);
	}

	/**
	 * post video-creative
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_video(params) {
		return this.api_server.post(CreativesService.URI_STATES.get("creatives.video.post"), params);
	}

	/**
	 * get creative
	 * @param {number} id
	 * @return {Promise<>}
	 */
	async get_video(id) {
		return this.api_server.get(CreativesService.URI_STATES.get("creatives.video.get").format(id));
		/*
		return {
			"data": {
				"id": 2391021,
				"title": "Creative 1",
				"status": 1,
				"advertiser_id": 1234567,
				"creative_file": "my_creative.mp4",
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"companion_ad": 1,
				"brand_name": "My Brand",
				"ad_title": "Super sale 20%",
				"logo_url": "http://mybrand.com/logo.png",
				"button_color": "#FF1100",
				"button_text": "Buy Now",
				"deep_link": "https://mybrand.com/about"
			},
			"meta": {
				"status": "ok"
			}
		};
		 */
	}

	/**
	 * put creative
	 * @param {number} id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update_video(id, params) {
		return this.api_server.put(CreativesService.URI_STATES.get("creatives.video.put").format(id), params);
		/*
		return {
			"data": {
				"id": 2391021,
				"title": "Creative 1",
				"status": 1,
				"advertiser_id": 1234567,
				"creative_file": "my_creative.mp4",
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"companion_ad": 1,
				"brand_name": "My Brand",
				"ad_title": "Super sale 20%",
				"logo_url": "http://mybrand.com/logo.png",
				"button_color": "#FF1100",
				"button_text": "Buy Now",
				"deep_link": "https://mybrand.com/about"
			},
			"meta": {
				"status": "ok"
			}
		};
		*/
	}

	/**
	 * post onsite display creative
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_display_onsite(params) {
		return this.api_server.post(CreativesService.URI_STATES.get("creatives.onsite_display.post"), params);
		/*
		return {
			"data": {
				"id": 2391021,
				"status": 1,
				"title": "Creative 1",
				"advertiser_id": 2398012,
				"third_party": 0,
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"creative_file": "string",
				"mraid": 1,
				"mraid_type": "banner",
				"tag": "<script>blah</script>",
				"ad_server": "MEDIAPLEX",
				"3pas_tag_id": "34FJF06JKV0",
				"tag_type": "SCRIPT",
				"ad_format": "EXPANDABLE",
				"expansion_trigger": "AUTOMATIC",
				"expansion_direction": "NONRESTRICTED",
				"dimension_type": "120x600",
				"file_type": "HTML5"
			},
			"meta": {
				"status": "ok"
			}
		};
		 */
	}

	/**
	 * post onsite display creative
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_display_onsite(params) {
		// return this.api_server.post(CreativesService.URI_STATES.get("creatives.onsite_display.post"), params);
		return {
			"data": {
				"id": 2391021,
				"status": 1,
				"title": "Creative 1",
				"advertiser_id": 2398012,
				"third_party": 0,
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"creative_file": "string",
				"mraid": 1,
				"mraid_type": "banner",
				"tag": "<script>blah</script>",
				"ad_server": "MEDIAPLEX",
				"3pas_tag_id": "34FJF06JKV0",
				"tag_type": "SCRIPT",
				"ad_format": "EXPANDABLE",
				"expansion_trigger": "AUTOMATIC",
				"expansion_direction": "NONRESTRICTED",
				"dimension_type": "120x600",
				"file_type": "HTML5"
			},
			"meta": {
				"status": "ok"
			}
		};
	}

	/**
	 * post display creative
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create_display(params) {
		return this.api_server.post(CreativesService.URI_STATES.get("creatives.display.post"), params);
		/*
		return {
			"data": {
				"id": 2391021,
				"status": 1,
				"title": "Creative 1",
				"advertiser_id": 2398012,
				"third_party": 0,
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"creative_file": "string",
				"mraid": 1,
				"mraid_type": "banner",
				"tag": "<script>blah</script>",
				"ad_server": "MEDIAPLEX",
				"3pas_tag_id": "34FJF06JKV0",
				"tag_type": "SCRIPT",
				"ad_format": "EXPANDABLE",
				"expansion_trigger": "AUTOMATIC",
				"expansion_direction": "NONRESTRICTED",
				"dimension_type": "120x600",
				"file_type": "HTML5"
			},
			"meta": {
				"status": "ok"
			}
		};
		 */
	}

	/**
	 * get display creative
	 * @param {number} id
	 * @return {Promise<>}
	 */
	async get_display(id) {
		return this.api_server.get(CreativesService.URI_STATES.get("creatives.display.get").format(id));
		/*
		return {
			"data": {
				"id": 2391021,
				"status": 1,
				"title": "Creative 1",
				"advertiser_id": 2398012,
				"third_party": 1,
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"creative_file": "string",
				"mraid": 1,
				"mraid_type": "banner",
				"tag": "<script>blah</script>",
				"ad_server": "MEDIAPLEX",
				"3pas_tag_id": "34FJF06JKV0",
				"tag_type": "SCRIPT",
				"ad_format": "EXPANDABLE",
				"expansion_trigger": "AUTOMATIC",
				"expansion_direction": "NONRESTRICTED",
				"dimension_type": "120x600",
				"file_type": "HTML5"
			},
			"meta": {
				"status": "ok"
			}
		};
		 */
	}

	/**
	 * put display creative
	 * @param {number} id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update_display(id, params) {
		return this.api_server.put(CreativesService.URI_STATES.get("creatives.display.put").format(id), params);
		/*
		return {
			"data": {
				"id": 2391021,
				"status": 1,
				"title": "Creative 1",
				"advertiser_id": 2398012,
				"third_party": 0,
				"clickthrough_url": "https://www.mediamath.com",
				"landing_page_url": "https://www.mediamath.com",
				"creative_file": "string",
				"mraid": 1,
				"mraid_type": "banner",
				"tag": "<script>blah</script>",
				"ad_server": "MEDIAPLEX",
				"tpas_tag_id": "34FJF06JKV0",
				"tag_type": "SCRIPT",
				"ad_format": "EXPANDABLE",
				"expansion_trigger": "AUTOMATIC",
				"expansion_direction": "NONRESTRICTED",
				"dimension_type": "120x600",
				"file_type": "HTML5"
			},
			"meta": {
				"status": "ok"
			}
		};
		 */
	}

	/**
	 * get list of creatives by agency
	 * @param {number} agency
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async _list_by_agency(agency, params) {
		return this.api_server.get(CreativesService.URI_STATES.get("creatives.list_by_agency").format(agency), params);
	}

	/**
	 * get video list of creatives by agency
	 * @param {number} agency
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async video_list(agency, params) {
		return this._list_by_agency(agency, Object.assign(params, {"creative_type": "video"}));
	}

	/**
	 * get onsite creatives by agency
	 * @param {number} agency
	 * @param {object} params
	 * @returns {Promise<unknown>}
	 */
	async onsite_creatives_by_agency(agency, params) {

		return this.api_server.get(CreativesService.URI_STATES.get("onsite_creatives.list_by_agency").format(agency), params);
		/*
		return {
			"data": [
				{
					"id": 2,
					"title": "Pepsi IOS",
					"advertiser_name": "Acme Org",
					"media_type": "display",
					"status": "approved",
					"end_date": "2020-12-31 12:00:00",
					"rejection_reason": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
				}, {
					"id": 3,
					"title": "Pepsi IOS 2",
					"advertiser_name": "Walmart Mexico",
					"media_type": "display",
					"status": "pending",
					"end_date": "2020-12-28 12:00:00",
					"rejection_reason": ""
				}, {
					"id": 4,
					"title": "Pepsi IOS 3",
					"advertiser_name": "Bank of America",
					"media_type": "display",
					"status": "rejected",
					"end_date": "2020-12-15 12:00:00",
					"rejection_reason": "some kind of content"
				}
			],
			"meta": {
				"status": "ok",
				"total": 10,
				"total_count": 100
			}
		};
		*/
	}

	/**
	 * get creative approval list
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async get_creative_approval_list(params) {
		return this.api_server.get(CreativesService.URI_STATES.get("onsite_creatives.get_list"), params);
		// return {"data": [{"advertiser_name": ". A B C test advertiser", "end_date": "Fri, 17 Mar 2023 11:00:31 GMT", "id": 101, "media_type": "display", "status": "pending", "title": "local 1"}, {"advertiser_name": ". A B C test advertiser", "end_date": "Fri, 17 Mar 2023 14:16:26 GMT", "id": 102, "media_type": "display", "status": "pending", "title": "local 2"}, {"advertiser_name": ". A B C test advertiser", "end_date": "Fri, 17 Mar 2023 14:25:50 GMT", "id": 104, "media_type": "display", "status": "pending", "title": "local 3"}], "meta": {"status": "ok", "total": 3, "total_count": 3}};
	}

	/**
	 * post local creative
	 * @param {object} params
	 * @returns {Promise<unknown>}
	 */
	async create_local(params) {
		return this.api_server.post(CreativesService.URI_STATES.get("onsite_creatives.post"), params);
	}

	/**
	 * Get creative approval by id
	 * @param {number} id
	 * @return {Promise<object>}
	 */
	async get_creative_approval(id) {
		return this.api_server.get(CreativesService.URI_STATES.get("onsite_creatives.get_creative").format(id))
		// return {"data": {"ad_tag": `<h1>test</h1>`, "advertiser_name": ". B r a d e n", "approval_status": "pending", "clickthrough_url": "http://www.mediamath.com/click", "end_date": "2023-04-01 13:45:00", "id": 40, "landing_page_url": "http://www.mediamath.com/landing", "media_type": "display", "mraid": 1, "mraid_type": "static", "rejected_reason": "", "title": "1st party test 1"}, "meta": {"status": "ok"}};
	}

	/**
	 * get display list of creatives by agency
	 * @param {number} agency
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async display_list(agency, params) {
		return this._list_by_agency(agency, Object.assign(params, {"creative_type": "display"}));
	}

	/**
	 * get list of concepts by agency
	 * @param {number} agency
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async concepts_by_agency(agency, params) {
		return this.api_server.get(CreativesService.URI_STATES.get("creatives.concepts_by_agency").format(agency), params);
	}

	/**
	 * post creative
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async create(params) {
		return this.api_server.post(CreativesService.URI_STATES.get("creatives.post"), params);
	}

	/**
	 * put creative
	 * @param {number} id
	 * @param {object} params
	 * @return {Promise<>}
	 */
	async update(id, params) {
		return this.api_server.put(CreativesService.URI_STATES.get("creatives.put").format(id), params);
	}

	/**
	 * Get creative by id
	 * @param {number} id
	 */
	async get(id) {
		return this.api_server.get(CreativesService.URI_STATES.get("creatives.get").format(id), {});
	}

	/**
	 * Get user out
	 * @param {object} params
	 */
	async list(params) {
		return {
			"meta": {
				"total": 12,
				"total_count": 12,
				"limit": 10,
				"status": "ok"
			},
			"data": [
				{
					"id": 1,
					"title": "Campaign 1",
					"advertiser_id": 1234567,
					"type": "PG",
					"status": 1,
					"budget": 2000,
					"spend_to_date": 155.33,
					"cpm": 2.89,
					"ctr": 4.20,
					"clicks": 100,
					"impressions": 12100,
					"start_date": "2020-01-01",
					"end_date": "2020-12-31"
				},
				{
					"id": 2,
					"title": "Campaign 2",
					"advertiser_id": 1134567,
					"type": "PG",
					"status": 0,
					"budget": 2000,
					"spend_to_date": 155.33,
					"cpm": 2.89,
					"ctr": 4.20,
					"clicks": 100,
					"impressions": 12100,
					"start_date": "2020-01-01",
					"end_date": "2020-12-31"
				}
			]
		}
		// return this.api_server.get(CampaignService.URI_STATES.get("campaigns.data"), {});
	}
}
