import uuid from "uuid/v4";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import React, {useCallback, useContext, useReducer, useState} from "react";
import {
	Button,
	Divider, Grid,
	Icon, Menu,
	Message,
	Pagination,
	Segment,
	Table
} from "semantic-ui-react";
import CreativesGridContext from "./context";
import {creativesActions as cA, creativeGridReducer} from "./reducers";
import {isNil} from "../../../libs/common_utils";
import {isNewTabClick, onBannerDismiss} from "../../../libs/component_utils";
import Pager from "../../../models/pager";
import CreativesService from "../../../services/creatives";
import {OnlineContext} from "../../../context/online-context";
import Filter from "./models/filter";
import FilterControl from "./filter_control";
import {NavLink} from "react-router-dom";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useIntl} from "react-intl";
import {CreativesApprovalGrid} from "./creative_approval";

const initialState = {
	"creatives": [],
	"pager": new Pager(),
	"filter": new Filter()
};

export const CreativesPage = ({history, creative_type}) => {
	const intl = useIntl();
	const {agency} = useContext(OnlineContext);
	const [state, dispatch] = useReducer(creativeGridReducer, initialState, undefined);
	const [gridLoading, setGridLoading] = useState(true);
	const showSuccessMessage = !isNil(history.location.state);
	let _isMounted = React.useRef(false);
	const services = React.useRef(new Map([["creatives", new CreativesService()]]));

	const {whitelabelChannelVideo, whitelabelChannelDisplay, whitelabelDoubleCreativeApproval} = useFlags();

	const isVideo = creative_type === "video";
	const isDisplay = creative_type === "display";
	const isCreativeApproval = creative_type === "creative_approval";

	/**
	 * initial load
	 */
	React.useEffect(() => {
		_isMounted.current = true;

		// load list of items
		(async () => {
			await getList(state.filter, state.pager);
		})();

		// clear cache
		return () => {
			_isMounted.current = false;
			state.pager.reset();
			state.filter.reset();
		}
	}, [creative_type]);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = async (e, {activePage}) => {
		state.pager.setPage(activePage);
		await getList(state.filter, state.pager);
	};

	/**
	 * get to create page based on type provided
	 * @param {boolean} isVideo
	 */
	const navigateToCreatePage = isVideo => {
		history.push((isVideo)? "/creative/video/create" : "/creative/display/create");
	};

	/**
	 * navigate user to edit page
	 */
	const navigateToEditPage = useCallback(id => {
		history.push((isVideo)? `/creative/video/edit/${id}` : `/creative/display/edit/${id}`,
			state.creatives.find(creative => id === creative.id));
	}, [state]);

	/**
	 * get edit page URL by creative id
	 */
	const getEditPageHref = useCallback((id) => (isVideo)? `/creative/video/edit/${id}` : `/creative/display/edit/${id}`, []);

	/**
	 * load creatives from API
	 * @param {object} [filter]
	 * @param {object} [pager]
	 * @return {Promise<void>}
	 */
	const getList = async (filter = null, pager = null) => {
		const creatives = services.current.get("creatives");
		try {
			setGridLoading(true);

			let params = {};
			if (filter) {
				params = Object.assign(params, filter.toRequestJson());
			}
			if (pager) {
				params = Object.assign(params, pager.toJson());
			}

			let r;
			if(isDisplay) {
				r = await creatives.display_list(agency, params);
			} else if(isVideo) {
				r = await creatives.video_list(agency, params);
			} else if(isCreativeApproval) {
				r = await creatives.onsite_creatives_by_agency(agency, params);
			} else {
				throw Error("Unknown creative type pass")
			}

			let meta = r.meta;
			meta.page = pager.page;
			dispatch({"type": cA.INIT, "data": r.data, "pager": meta});
		} catch (e) {
			// ignore error
			console.log(e);
		} finally {
			if(_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * filter strategies
	 * @param {object} json
	 */
	const filtering = async json => {
		state.pager.reset();
		state.filter.fromJson(json);
		await getList(state.filter, state.pager);
	};

	if(!whitelabelChannelVideo && !whitelabelChannelDisplay && !whitelabelDoubleCreativeApproval) {
		return <Redirect to="/campaigns" />
	}

	/**
	 * check that user has access to this page
	 * @return {boolean}
	 */
	const accessRestricted = () => {
		return (!whitelabelChannelVideo && isVideo) || (!whitelabelChannelDisplay && isDisplay) || (!whitelabelDoubleCreativeApproval && isCreativeApproval);
	}

	if(accessRestricted()) {
		return <Redirect to="/creatives" />
	}

	return (
		<Segment basic style={{"padding": "0"}}>
			<CreativesGridContext.Provider value={{
				"navigateToCreatePage": () => navigateToCreatePage(isVideo), navigateToEditPage, getEditPageHref,
				"getPage": getPage, "ld_flags": {whitelabelChannelVideo, whitelabelChannelDisplay, whitelabelDoubleCreativeApproval}, filtering,
				gridLoading
			}}>
				{showSuccessMessage && (<CreativesSuccessMessage
					details={history.location.state || {}}
				/>)}
				<h1>
					{intl.formatMessage({
						id: "HEADING_CREATIVES",
						defaultMessage: "Creatives",
					})}
				</h1>
				<Divider hidden />
				{isCreativeApproval? <CreativesApprovalGrid
					items={state.creatives}
					controls={{
						"filter": state.filter,
						"pager": {
							"page": state.pager.page,
							"total_pages": state.pager.total_pages,
							getPage,
						},
					}}
				/> : <CreativesGrid
					creative_type={creative_type}
					items={state.creatives}
					controls={{
						"filter": state.filter,
						"pager": {
							"page": state.pager.page,
							"total_pages": state.pager.total_pages,
							getPage,
						},
					}}
				/>}

			</CreativesGridContext.Provider>
		</Segment>
	)
};

/**
 * Generate success message
 * @param {object} details
 * @return {*}
 * @constructor
 */
const CreativesSuccessMessage = ({details}) => {
	const intl = useIntl();

	let timer;
	React.useEffect(() => {
		timer = setTimeout(onBannerDismiss, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (details.action === "created") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_CREATIVE_CREATED",
					defaultMessage: "Creative successfully created",
				})}
			</Message>
		);
	} else if (details.action === "updated") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage(
					{
						id: "MESSAGE_CREATIVE_UPDATED",
						defaultMessage: "Creative {name} updated",
					},
					{
						name: details.name,
					}
				)}
			</Message>
		);
	}

	return <></>
};
CreativesSuccessMessage.propTypes = {
	"details": PropTypes.object.isRequired
};

/**
 * Render grid
 * @param {array} items
 * @param {object} controls
 * @param {string} creative_type
 */
export const CreativesGrid = ({ items, controls, creative_type}) => {
	const intl = useIntl();
	const context = React.useContext(CreativesGridContext);
	const isVideo = creative_type === "video";
	const isDisplay = creative_type === "display";
	const {whitelabelChannelDisplay, whitelabelChannelVideo, whitelabelDoubleCreativeApproval} = context.ld_flags;

	/**
	 * generate button title based on creative_type
	 * @param {boolean} isVideo
	 * @returns {string}
	 */
	const getCreateButtonTitle = isVideo => {
		return isVideo
			? intl.formatMessage({
					id: "BTN_ADD_VIDEO_CREATIVE",
					defaultMessage: "Add Video Creative",
			  })
			: intl.formatMessage({
					id: "BTN_ADD_DISPLAY_CREATIVE",
					defaultMessage: "Add Display Creative",
			  });
	}

	return (
		<>
			<Grid className="common_grid">
				<Grid.Row>
						<Grid.Column>
							<Menu pointing secondary>
								{whitelabelChannelDisplay && (
									<Menu.Item
										as={NavLink}
										active={isDisplay}
										to="/creatives/display"
										content={intl.formatMessage({
											id: "LINK_DISPLAY",
											defaultMessage: "Display",
										})}
									/>
								)}
								{whitelabelChannelVideo && (
									<Menu.Item
										as={NavLink}
										active={isVideo}
										to="/creatives/video"
										content={intl.formatMessage({
											id: "LINK_VIDEO",
											defaultMessage: "Video",
										})}
									/>
								)}
								{whitelabelDoubleCreativeApproval && (
									<Menu.Item
										as={NavLink}
										active={false}
										to="/creatives/approve"
										content={intl.formatMessage({
											id: "LINK_ON_SITE_CREATIVES",
											defaultMessage: "On-Site Creatives",
										})}
									/>
								)}
								<Menu.Menu position="right">
									<Menu.Item>
										<Button
											primary
											compact
											className="text__uppercase"
											onClick={() => context.navigateToCreatePage(isVideo)}
											content={getCreateButtonTitle(isVideo)}
										/>
									</Menu.Item>
								</Menu.Menu>
							</Menu>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Segment basic style={{"padding": "0"}} loading={context.gridLoading}>
				<Grid className="common_grid">
					<Grid.Row>
						<Grid.Column>
							<FilterControl
								isVideo={isVideo}
								filter={controls.filter}
								onChange={context.filtering}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
						<Table className="custom-table">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>
										{intl.formatMessage({
											id: "LABEL_CREATIVE_NAME",
											defaultMessage: "Creative Name",
										})}
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="left">
										{intl.formatMessage({
											id: "LABEL_STATUS",
											defaultMessage: "Status",
										})}
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="left">
										{intl.formatMessage({
											id: "LABEL_CREATIVE_ID",
											defaultMessage: "Creative ID",
										})}
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="left">
										{intl.formatMessage({
											id: "LABEL_ADVERTISER",
											defaultMessage: "Advertiser",
										})}
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="center" style={{ "width": "20px" }}>
										&nbsp;
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{items.map((item) => {
									return <CreativeGridItem key={uuid()} {...item} />;
								})}
								{!items.length && (
									<GridEmptyRow
										filterIsEmpty={controls.filter.isEmpty()}
										buttonTitle={getCreateButtonTitle(isVideo)}
										onAddButtonClick={context.navigateToCreatePage}
									/>
								)}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									<Table.Cell colSpan="5" textAlign="right">{controls.pager.total_pages > 1 && <Pagination
										size="mini"
										defaultActivePage={controls.pager.page}
										totalPages={controls.pager.total_pages}
										firstItem={null}
										lastItem={null}
										onPageChange={context.getPage}
									/>}
									</Table.Cell>
								</Table.Row>
							</Table.Footer>
						</Table>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</>
	)
};
CreativesGrid.propTypes = {
	"items": PropTypes.array.isRequired,
	"controls": PropTypes.object.isRequired
};

const GridEmptyRow = ({ filterIsEmpty, buttonTitle, onAddButtonClick }) => {
	const intl = useIntl();

	return (
		<Table.Row>
			<Table.Cell colSpan="5" textAlign="center">
				{filterIsEmpty ? (
					<>
						{intl.formatMessage({
							id: "EMPTY_CREATIVES",
							defaultMessage: "You don’t have any creatives yet",
						})}
						<br />
						<br />
						<Button
							className="text__uppercase"
							primary
							compact
							onClick={onAddButtonClick}
						>
							{buttonTitle}
						</Button>
					</>
				) : (
					intl.formatMessage({
						id: "EMPTY_SEARCH_RESULTS",
						defaultMessage: "No results found",
					})
				)}
			</Table.Cell>
		</Table.Row>
	);
};

/**
 * Generate grid item
 * @param {object} item
 * @return {JSX.Element}
 * @constructor
 */
const CreativeGridItem = item => {
	const intl = useIntl();
	const context = React.useContext(CreativesGridContext);

	return (
		<>
			<Table.Row className={item.status ? "" : " inactive"}>
				<Table.Cell className="text-ellipsis">{item.title}</Table.Cell>
				<Table.Cell>
					{item.status
						? intl.formatMessage({
								id: "STATUS_ACTIVE",
								defaultMessage: "Active",
						  })
						: intl.formatMessage({
								id: "STATUS_INACTIVE",
								defaultMessage: "Inactive",
						  })}
				</Table.Cell>
				<Table.Cell>{item.id}</Table.Cell>
				<Table.Cell>{item.advertiser_name}</Table.Cell>
				<Table.Cell data-clickable="0" textAlign="center">
					<a
						href={context.getEditPageHref(item.id)}
						onClick={(e) => {
							if (isNewTabClick(e)) return;
							e.preventDefault();
							e.stopPropagation();
							context.navigateToEditPage(item.id);
						}}
					>
						<Icon name="edit" className="control" />
					</a>
				</Table.Cell>
			</Table.Row>
		</>
	);
};
