import {useState, useCallback, useRef, useLayoutEffect} from "react";
import {isUndefined} from "./common_utils";
import {useIntl} from "react-intl";

export const useForm = (cb, initial_state={}, validation) => {
	const [values, setValues] = useState(initial_state);
	const [errors, setErrors] = useState({});
	const intl = useIntl();

	/**
	 * Handle text input.
	 * @param {Event} e
	 * @param {object} input
	 */
	const onChange = (e, input) => {
		let el_name, el_value;
		if(!isUndefined(input)) {
			el_name = input.name;
			el_value = input.value;
		} else {
			el_name = e.target.name;
			el_value = e.target.value;
		}

		setValues({...values, [el_name]: el_value});
	};

	/**
	 * Handle checkbox input.
	 * @param {Event} e
	 * @param {HTMLInputElement} input
	 */
	const onSwitch = (e, {name, value, checked}) => {
		setValues({...values, [name]: (checked)? value : 0});
	};

	/**
	 * Handle form submission.
	 * @param {React.FormEvent & { target: HTMLFormElement }} e
	 * @param  {...any} args - passed to form callback
	 */
	const onSubmit = (e, ...args) => {
		e.preventDefault();

		// reset errors
		setErrors({});

		let form_errors = {}, firstItem;
		if(!e.target.checkValidity()) {
			e.target.querySelectorAll(":invalid").forEach((el) => {
				// Drop old custom validation message so it doesn't get stuck:
				el.setCustomValidity("");

				if(!firstItem) {
					firstItem = el;
				}

				let error_abbr;
				if(el.validity.valueMissing) {
					error_abbr = "VALIDATION_VALUE_MISSING";
				} else if(el.validity.rangeUnderflow) {
					error_abbr = "VALIDATION_RANGE_UNDERFLOW";
				} else if(el.validity.rangeOverflow) {
					error_abbr = "VALIDATION_RANGE_OVERFLOW";
				} else if(el.validity.tooShort) {
					error_abbr = "VALIDATION_TOO_SHORT";
				} else if(el.validity.typeMismatch) {
					// eslint-disable-next-line default-case
					switch(el.getAttribute("type")) {
						case("email"):
							error_abbr = "VALIDATION_INVALID_EMAIL";
						break;

						case("url"):
							error_abbr = "VALIDATION_INVALID_URL";
						break;
					}
				}

				const error_message = (error_abbr)? intl.formatMessage({
					id: error_abbr,
					defaultMessage: el.validationMessage,
				}, {
					"min_value": el.getAttribute("min"),
					"max_value": el.getAttribute("max"),
					"min_length": el.getAttribute("minlength"),
					"value_length": el.value.toString().length
				}) : el.validationMessage;

				// updage custom validity
				if(error_message) {
					el.setCustomValidity(error_message);

					form_errors[el.name] =
						el.validity.patternMismatch && el.title
							? el.title
							: error_message;
				}
			});
		}

		const custom_errors = validation(e, values);
		if(Object.keys(custom_errors).length === 0 && Object.keys(form_errors).length === 0) {
			cb(values, ...args);
		} else {
			if(firstItem && firstItem.focus) {
				firstItem.focus();
			}
			setErrors(Object.assign(custom_errors, form_errors));
		}
	};

	/**
	 * Handle custom input for more complex controls.
	 * @param {object} newValues
	 */
	const updateValues = (newValues) => {
		setValues((oldValues) => ({ ...oldValues, ...newValues }));
	};

	const loadData = data => {
		setValues(data);
	};

	return {
		values, errors, setErrors,
		onChange, onSubmit, loadData, "onBlur": onChange, onSwitch, updateValues
	}
};

export function useFormInput(initValue="") {
	const [value, setValue] = useState(initValue),
		[error, setError] = useState(false),
		[error_message, setErrorMessage] = useState("");

	const handleChange = useCallback((e, el) => {
		if(value !== el.value) {
			setValue(el.value);
		}
	}, [value]);

	const handleError = useCallback(message => {
		if(message !== error_message) {
			setErrorMessage(message);
			setError(true);
		}
	}, []);

	const resetError = useCallback(() => {
		if(error) {
			setErrorMessage("");
			setError(false);
		}
	}, []);

	const onBlur = useCallback(e => {
		if(!e.target.checkValidity()) {
			handleError(e.target.validationMessage);
		} else {
			resetError();
		}
	}, []);

	return {
		value,
		error,
		error_message,
		onBlur,
		"onChange": handleChange,
		"setError": handleError,
		"resetError": resetError
	};
}

/**
 * Determines if mouse click is a common gesture for the
 * “open in new tab” command (Ctrl/Cmd + Click or middle mouse button).
 * @param {React.MouseEvent} e
 */
export function isNewTabClick(e) {
	return e.button === 1 || e.metaKey || e.ctrlKey;
}

/**
 * Joins a string of CSS class names, ignoring false-y values.
 * @param  {...any} names
 */
export const classNames = (...names) => names.filter(x => x).join(" ");

const MIN_SEARCH_SYMBOLS = 3;

/**
 * check if we should do a search
 * @param {string} query
 * @param {string} prev_query
 * @return {boolean}
 */
export const should_do_search = (query, prev_query) => {
	if(prev_query.length > 0 && query.length < 1) {
		return true;
	}

	return query.length >= MIN_SEARCH_SYMBOLS;
};

/**
 * handle the case when we had query but user clear the field
 * @param query
 * @param prev_query
 * @return {boolean}
 */
export const search_query_becomes_empty = (query, prev_query) => {
	return (prev_query.length > 0 && query.length < 1);
};

/**
 * Debounces a callback using a simple timer. useCallback is applied automatically.
 * @param {number} delay
 * @param {*} callback
 */
export const useDebounce = (delay, callback) => {
	const timerRef = useRef();
	return useCallback((...args) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(() => callback(...args), delay);
	});
};

/**
 * Checks if current component is mounted (e. g. to prevent unnecessary setState
 * calls). When possible, canceling in-flight requests on component unmount should
 * be preferred, but this is often not practical, and this hook can be used instead.
 *
 * @see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * @returns {boolean}
 */
export const useMounted = () => {
	const isMounted = useRef(false);
	useLayoutEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);
	return isMounted;
}

/**
 * handle max/maxlength for number type
 * @param {Event} e
 * @param {object} input
 */
export const handleNumber = (e, input) => {
	e.preventDefault();
	e.stopPropagation();

	if(input.value > input.max || input.value.length > input.max.length) {
		e.target.value = input.value.slice(0, -1);
	}
};

/**
 * remove banner
 */
export const onBannerDismiss = () => {
	const node = document.querySelector(".page-success-message");
	if(node) {
		node.remove();
	}
};
