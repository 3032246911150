import React, { useCallback, useState, useEffect } from "react";
import {
	Divider,
	Header,
	Segment,
	Confirm,
} from "semantic-ui-react";
import { useIntl } from "react-intl";
import isEqual from "lodash/isEqual";

import CampaignsService from "../../../services/campaign";
import CampaignForm from "../form";
import {parseAPIDateTime, formatAPIDateTime, getCampaignTypeBasedOnLD} from "../../../libs/common_utils";
import {useFlags} from "launchdarkly-react-client-sdk";
import {LangContext} from "../../../context";

/**
 * Campaign update page component
 * @param history
 * @param match
 * @return {*}
 * @constructor
 */
const CampaignEditPage = ({ history, match }) => {
	const intl = useIntl();
	const campaignsService = new CampaignsService(),
		campaign_id = match.params.id;
	const {whitelabelSupplyPmpFlag, whitelabelSupplyAudienceFlag, whitelabelSupplyOpenFlag} = useFlags();

	const lang_context = React.useContext(LangContext);
	const parent_url = "/campaigns";

	const handleConfirm = useCallback(() => {
		history.push(parent_url);
	}, []);

	/**
	 * Update campaign
	 * @param {object} values
	 * @return {Promise<void>}
	 */
	const handleSubmit = async values => {
				try {
			const flights = values["budget_flights"].map(flight => {
				const data = {
					...flight,
					"total_budget": parseFloat(flight["total_budget"]),
					"start_date": formatAPIDateTime(flight["start_date"]),
					"end_date": formatAPIDateTime(flight["end_date"])
				}
	
				if(data["total_impression_budget"]) { data["total_impression_budget"] = parseFloat(data["total_impression_budget"]) }
				else { delete data["total_impression_budget"] };
				
				return data;
			});

			const data = {
				...values,
				"budget_flights": flights,
				"status": values["status"] ? 1 : 0,
				"spend_cap_automatic": values["spend_cap_automatic"] ? 1 : 0,
				"goal_value": !values["goal_value"]? "" : parseFloat(values["goal_value"]),
				"spend_cap_amount": !values["spend_cap_amount"]? "" : parseFloat(values["spend_cap_amount"]),
				"frequency_amount": parseInt(values["frequency_amount"] || 0, 10),
				"margin_pct": values["margin_pct"] === null ? null : parseFloat(values["margin_pct"] || 0),
			};

			if(data["margin_pct"] === null) delete data["margin_pct"];
			if(data["site_list_ids"].length <= 1) {
				const id = data["site_list_ids"][0];
				data["site_list_id"] = id || null;
			}
			delete data["site_list_ids"];
			// Right now backend attempts to validate some values even if they aren't used,
			// but does work alright if they aren't present at all, so we delete them as
			// a temporary measure:

			if (values["campaign_type"].toLowerCase() === "pg") {
				delete data["spend_cap_type"];
				delete data["spend_cap_automatic"];
				delete data["spend_cap_amount"];
				delete data["frequency_optimization"];
				delete data["frequency_type"];
				delete data["frequency_amount"];
			} else {
				if (values["spend_cap_type"] === "no-limit") {
					delete data["spend_cap_automatic"];
					delete data["spend_cap_amount"];
				} else if (values["spend_cap_automatic"] === 1) {
					delete data["spend_cap_amount"];
				}

				if (values["frequency_optimization"]) {
					delete data["frequency_type"];
					delete data["frequency_amount"];
					delete data["frequency_interval"];
				} else if (values["frequency_type"] === "no-limit") {
					delete data["frequency_amount"];
					delete data["frequency_interval"];
				}
			}
			
			const r = await campaignsService.update(campaign_id, data);
			history.push(parent_url, {"action": "updated", "name": r.data.title});
		} catch(e) {
			throw e;
		}
	};



	const [initialData, setInitialData] = useState(null);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		(async () => {
			try {
				const r = await campaignsService.get(campaign_id);
				let campaign = {...r.data};

				const non_pg_type = getCampaignTypeBasedOnLD(whitelabelSupplyPmpFlag,
					whitelabelSupplyAudienceFlag, whitelabelSupplyOpenFlag,
					lang_context);
				
				
				const flights = campaign["budget_flights"].map(flight => (
					{
						...flight,
						"start_date": parseAPIDateTime(flight["start_date"]),
						"end_date": parseAPIDateTime(flight["end_date"])
					}
				));
				
				let json = {
					...campaign,
					"campaign_name": campaign["campaign_name"],
					"campaign_type": campaign["is_programmatic_guaranteed"]
					? "PG" : (non_pg_type)? non_pg_type : campaign["type"],
					"currency": campaign["currency_code"],
					"budget_flights": flights,
					"spend_cap_automatic": +Boolean(campaign["spend_cap_automatic"]),
					"spend_cap_amount": campaign["spend_cap_amount"] === 0? "" : campaign["spend_cap_amount"],
					"frequency_amount": campaign["frequency_amount"] === 0? "" : campaign["frequency_amount"],
					"restrict_targeting_to_same_device_id": +Boolean(campaign["restrict_targeting_to_same_device_id"]),
					"merit_pixel_id": campaign?.merit_pixel_id || "",
					"pc_window_days": campaign?.pc_window_days || 30,
					"pv_window_days": campaign?.pv_window_days || 7,
					"margin_pct": campaign?.margin_pct || 0,
					"site_list_ids": campaign?.site_list_ids || [],
				};
				
				setInitialData(json);
			} catch (e) {
				console.log(e);
			}
		})();
	}, [campaign_id]);

	const handleCancel = useCallback((data) => {
		if (!isEqual(data, initialData)) {
			setOpen(true);
		} else {
			handleConfirm();
		}
	});

	if (initialData === null) {
		return (<Segment disabled tertiary textAlign="center" className="loading" style={{"height": "300px"}}>
			&nbsp;
		</Segment>);
	}

	return (
		<Segment basic>
			<Header as="h2">
				{intl.formatMessage({
					id: "HEADING_EDIT_CAMPAIGN",
					defaultMessage: "Edit Campaign",
				})}
			</Header>
			<Divider />
			<CampaignForm
				initialData={initialData}
				formType="edit"
				submitText={intl.formatMessage({
					id: "BTN_SAVE_CHANGES",
					defaultMessage: "Save Changes",
				})}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
			/>
			<Confirm
				open={open}
				content={intl.formatMessage({
					id: "BODY_CONFIRM_LEAVE",
					defaultMessage: "Are you sure you want to cancel? By clicking OK, campaign editing will be aborted and entered data will be lost.",
				})}
				cancelButton={intl.formatMessage({
					id: "BTN_CANCEL",
					defaultMessage: "Cancel",
				})}
				confirmButton={intl.formatMessage({
					id: "BTN_OK",
					defaultMessage: "OK",
				})}
				onCancel={() => setOpen(false)}
				onConfirm={handleConfirm}
				size="mini"
			/>
		</Segment>
	);
};

export default CampaignEditPage;
