import React, { useRef, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { useIntl } from "react-intl";
import {
	Button,
	Divider,
	Form,
	Input,
	Message,
	Radio,
	Ref,
} from "semantic-ui-react";

import { useForm, useMounted } from "../../../libs/component_utils";
import SiteListService from "../../../services/site-list";
import { FileInputComponent } from "../../common/file_input";

/**
 * Site List create form component
 * @param history
 * @return {*}
 * @constructor
 */
const SiteListForm = ({
	initialData,
	onSubmit,
	onCancel,
	submitButtonLabel = "Create Site List",
}) => {
	const intl = useIntl();
	const fileRef = useRef();
	const [serverError, setServerError] = useState("");
	const [formSending, setFormSending] = useState(false);
	const isMounted = useMounted();

	/**
	 * Save site-list data
	 * @return {Promise<void>}
	 */
	const addSiteListForm = async () => {
		const service = new SiteListService();
		setFormSending(true);

		try {
			const form = new FormData();

			Object.entries(values).forEach(([key, value]) => {
				form.append(key, value);
			});

			form.set("status", values.status ? "1" : "0");
			form.append(
				"site_list_file",
				fileRef.current.querySelector("[type='file']")?.files[0]
			);

			await service.create(form);
			onSubmit();
		} catch (e) {
			console.log("e :>> ", e);
			setServerError(e.error.message);
		} finally {
			if (isMounted.current) {
				setFormSending(false);
			}
		}
	};

	/**
	 * do a custom validation
	 * @param e
	 * @param {object} fields
	 */
	const postValidation = (e, fields) => {
		const errors = {};

		if (fields.title.length < 3) {
			errors.title = intl.formatMessage({
				id: "ERROR_MIN_TITLE_REQUIRED",
				defaultMessage: "At least 3 characters are required",
			});
		}

		const fileInput = fileRef.current.querySelector("[type='file']");
		const isFileAttached = Boolean(fileInput?.files.length);

		if (!isFileAttached) {
			errors.site_list_file = intl.formatMessage({
				id: "ERROR_EMPTY_SITE_LIST_FILE",
				defaultMessage: "Please select the file to upload",
			});
		} else if (isFileAttached && fileInput.files[0].type !== "text/csv") {
			errors.site_list_file = intl.formatMessage({
				id: "ERROR_SITE_LIST_FILE_WRONG_FORMAT",
				defaultMessage: "Asset must be a CSV file",
			});
		}

		return errors;
	};

	const {
		values,
		errors,
		onChange,
		onSubmit: handleSubmit,
	} = useForm(addSiteListForm, initialData, postValidation);

	return (
		<>
			<Message
				style={{ marginTop: "10px" }}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<Form
				onSubmit={handleSubmit}
				size="small"
				loading={formSending}
				noValidate
				error={Boolean(Object.keys(errors).length)}
				autoComplete="off"
			>
				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_STATUS",
							defaultMessage: "Status",
						})}
					</label>
					<Radio
						name="status"
						label={intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
						})}
						value={1}
						checked={values.status}
						onChange={onChange}
					/>
					<Radio
						style={{ marginLeft: "15px" }}
						name="status"
						label={intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
						})}
						value={0}
						checked={!values.status}
						onChange={onChange}
					/>
				</Form.Field>

				<Form.Field required inline error={errors.title}>
					<label>
						{intl.formatMessage({
							id: "LABEL_SITE_LIST_NAME",
							defaultMessage: "Site List Name",
						})}
					</label>
					<Input
						name="title"
						required
						maxLength="64"
						defaultValue={values.title}
						onBlur={onChange}
					/>
					<div className="custom-error">{errors.title}</div>
				</Form.Field>

				<Form.Field inline>
					<label>
						{intl.formatMessage({
							id: "LABEL_SITE_LIST_TYPE",
							defaultMessage: "Site List Type",
						})}
					</label>
					<Radio
						name="restriction"
						label={intl.formatMessage({
							id: "RESTRICTION_ALLOW_LIST",
							defaultMessage: "Allow List",
						})}
						value={"allow"}
						checked={values.restriction === "allow"}
						onChange={onChange}
					/>
					<Radio
						style={{ marginLeft: "15px" }}
						name="restriction"
						label={intl.formatMessage({
							id: "RESTRICTION_BLOCK_LIST",
							defaultMessage: "Block List",
						})}
						value={"block"}
						checked={values.restriction === "block"}
						onChange={onChange}
					/>
				</Form.Field>

				<Form.Field inline required error={!!errors?.site_list_file}>
					<label>
						{intl.formatMessage({
							id: "LABEL_UPLOAD_FILE",
							defaultMessage: "Upload File",
						})}
					</label>
					<Ref innerRef={fileRef}>
						<FileInputComponent
							accept=".csv"
							required
							placeholder={intl.formatMessage({
								id: "HINT_UPLOAD_ASSET_COMPUTER",
								defaultMessage: "Upload an asset from your computer",
							})}
						/>
					</Ref>
					<div className="custom-error">{errors.site_list_file}</div>
				</Form.Field>

				<Divider hidden />
				<Form.Field align="right" className="create-controls">
					<Button size="tiny" type="button" onClick={() => onCancel(values)}>
						{intl.formatMessage({
							id: "BTN_CANCEL",
							defaultMessage: "Cancel",
						})}
					</Button>
					<Button size="tiny" className="create" type="submit">
						{submitButtonLabel}
					</Button>
				</Form.Field>
			</Form>
		</>
	);
};

export default SiteListForm;
