import { formatAPIDate } from "../libs/common_utils";

export default class ReportModel {
	constructor(
		type,
		start_date,
		end_date,
		advertiser_id,
		campaign_ids,
		dimensions,
		metrics,
		output
	) {
		this.type = type || null;
		this.start_date = start_date || null;
		this.end_date = end_date || null;
		this.advertiser_id = advertiser_id || 0;
		this.campaign_ids = campaign_ids || [];
		this.dimensions = dimensions || [];
		this.metrics = metrics || [];
		this.output = output || "json";
	}

	/**
	 * convert model into json format
	 * @return {object}
	 */
	toJson() {
		return {
			...this,
			"start_date": formatAPIDate(this["start_date"]),
			"end_date": formatAPIDate(this["end_date"]),
		};
	}
}
