import createAuth0Client from "@auth0/auth0-spa-js";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Modal } from "semantic-ui-react";
import Registry from "./libs/register_storage";

const DEFAULT_REDIRECT_CB = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CB, ...initOptions }) => {
  const storage = new Registry(window.localStorage);
  const storedToken = storage.hasItem("token");

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [signingIn, setSignIn] = useState(false);
  const [signingOut, setSignOut] = useState(false);

  const loginWithPopup = useCallback(
    async (params = {}) => {
      setPopupOpen(true);

      try {
        await auth0Client.loginWithPopup(params, { timeoutInSeconds: 120 });
      } catch (error) {
        console.error(error);
      } finally {
        setPopupOpen(false);
      }

      const user = await auth0Client.getUser();
      setUser(user);
      setIsAuthenticated(true);
    },
    [auth0Client],
  );

  const handleRedirectCallback = useCallback(async () => {
    setSignIn(true);
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    setSignIn(false);
  }, [auth0Client]);

  /**
   * get user data
   * @return {Promise<any>}
   */
  const getUser = useCallback(async () => {
    setSignIn(true);
    const user = await auth0Client.getUser();
    setSignIn(false);
    return user;
  }, [auth0Client]);

  const doLogout = useCallback(async () => {
    // ignore method in case process already run
    if (signingOut) {
      return;
    }

    setSignOut(true);
    let auth0FromHook = auth0Client;

    if (!auth0FromHook) {
      auth0FromHook = await createAuth0Client(initOptions);
    }

    try {
      await auth0FromHook.logout({ returnTo: initOptions.redirect_uri });
    } catch (err) {
      console.error(err);
    }
  }, [auth0Client, initOptions, signingOut]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only runs on init
  useEffect(() => {
    if (storedToken) {
      setLoading(false);
      return;
    }

    (async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    })();
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        getIdTokenClaims: (...args) => auth0Client.getIdTokenClaims(...args),
        getTokenSilently: (...args) => auth0Client.getTokenSilently(...args),
        getTokenWithPopup: (...args) => auth0Client.getTokenWithPopup(...args),
        getUser,
        handleRedirectCallback,
        isAuthenticated,
        loading,
        loginWithPopup,
        loginWithRedirect: (...args) => auth0Client.loginWithRedirect(...args),
        logout: (...args) => doLogout(...args),
        popupOpen,
        signingOut,
        user,
      }}
    >
      <Modal open={signingOut || signingIn} dimmer="blurring" className="auth0_modal">
        <Modal.Header>Please wait, {signingOut ? "signing out..." : "signing in..."}</Modal.Header>
      </Modal>
      {!(loading || signingIn || signingOut) && children}
    </Auth0Context.Provider>
  );
};
