import PropTypes from "prop-types";
import React from "react";
import { List, Card, Checkbox, Loader, Input } from "semantic-ui-react";
import { classNames } from "../../../libs/component_utils";
import "./miller-picker.css";

export default function MillerPicker({ disabled, children, ...props }) {
	return (
		<div
			className={classNames("miller-picker", disabled && "disabled")}
			{...props}
		>
			{children}
		</div>
	);
}

const MillerPickerColumn = ({
	title,
	items,
	loading,
	selection,
	value: columnValue,
	indeterminate,
	onChange,
	activeItem,
	onSelect,
	search = false,
	searchQuery = "",
	searchPlaceholder = "Search",
	onSearchChange,
	...props
}) => (
	<Card className="miller-picker-column" {...props}>
		<Card.Content className="header-content">
			<Card.Header>
				<Checkbox
					checked={
						items &&
						columnValue &&
						items.length > 0 &&
						items.every(({ value }) => columnValue.includes(value))
					}
					indeterminate={
						items &&
						columnValue &&
						items.some(({ value }) => columnValue.includes(value)) &&
						!items.every(({ value }) => columnValue.includes(value))
					}
					onChange={(e, { checked }) => {
						e.stopPropagation();
						onChange(checked ? items.map(({ value }) => value) : []);
					}}
				/>
				{title}
			</Card.Header>
		</Card.Content>
		<Card.Content extra className="miller-picker-list">
			{loading ? (
				<Loader active />
			) : (
				<List selection={selection}>
					{items &&
						items.map(({ value, text }) => (
							<List.Item
								key={value}
								onClick={onSelect ? () => onSelect(value) : null}
								active={value === activeItem}
							>
								<Checkbox
									checked={columnValue.includes(value)}
									indeterminate={
										indeterminate &&
										indeterminate.has(value) &&
										!columnValue.includes(value)
									}
									onChange={(e, { checked }) => {
										e.stopPropagation();
										onChange(
											checked
												? [...columnValue, value]
												: columnValue.filter((v) => value !== v)
										);
									}}
								/>
								{text}
							</List.Item>
						))}
				</List>
			)}
		</Card.Content>
		{search ? (
			<Card.Content extra className="miller-picker-search">
				<Input
					fluid
					transparent
					value={searchQuery}
					onChange={(e, { value }) => onSearchChange(value)}
					placeholder={searchPlaceholder}
					icon={
						searchQuery !== ""
							? {
									name: "x",
									className: "input-clear-icon",
									link: true,
									onClick: () => onSearchChange(""),
							  }
							: "search"
					}
					iconPosition={searchQuery !== "" ? null : "left"}
					autoComplete="off"
				/>
			</Card.Content>
		) : null}
	</Card>
);

MillerPickerColumn.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool,
	selection: PropTypes.bool,
	value: PropTypes.array.isRequired,
	indeterminate: PropTypes.instanceOf(Set),
	onChange: PropTypes.func.isRequired,
	activeItem: PropTypes.any,
	onSelect: PropTypes.func,
	search: PropTypes.bool,
	searchQuery: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	onSearchChange: PropTypes.func,
};

MillerPicker.Column = MillerPickerColumn;
