import uuid from "uuid/v4";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useReducer, useState } from "react";
import { NavLink } from "react-router-dom";
import {
	Button,
	Divider,
	Grid,
	Icon, Input,
	Menu,
	Message,
	Pagination,
	Segment,
	Table, Form
} from "semantic-ui-react";
import AudiencesGridContext from "./context";
import { audiencesActions, audienceGridReducer } from "./reducers";
import {
	isNewTabClick,
	onBannerDismiss,
	search_query_becomes_empty,
	should_do_search
} from "../../../libs/component_utils";
import {getReadableClientName, isNil} from "../../../libs/common_utils";
import Pager from "../../../models/pager";
import AudiencesService from "../../../services/audiences";
import { OnlineContext } from "../../../context/online-context";
import {Config} from "../../../config/api";
import { useIntl } from "react-intl";
import {useFlags} from "launchdarkly-react-client-sdk";
import FilterControl from "../../pixel/index/filter_control";

const initialState = {
	"audiences": [],
	"pager": new Pager(),
	"total_pages": 0,
};

export const AudiencesPage = ({history}) => {
	const {agency} = useContext(OnlineContext);
	const [state, dispatch] = useReducer(audienceGridReducer, initialState);
	const [gridLoading, setGridLoading] = useState(true);
	const showSuccessMessage = !isNil(history.location.state);
	let _isMounted = React.useRef(false);
	const services = React.useRef(new Map([["audiences", new AudiencesService()]]));
	const intl = useIntl();

	const {whitelabelUploadDeviceButton} = useFlags();

	const query = React.useRef(""),
		timer = React.useRef(0);

	/**
	 * initial load
	 */
	React.useEffect(() => {
		_isMounted.current = true;
		// load list of items
		getList().then(() => console.log);

		// clear cache
		return () => {
			_isMounted.current = false;
		};
	}, []);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, {activePage}) => {
		state.pager.setPage(activePage);
		getList(state.pager).then(() => console.log);
	};

	/**
	 * get to create page
	 */
	const navigateToCreatePage = () => {
		history.push("/audience/create");
	};

	/**
	 * navigate user to edit page
	 */
	const navigateToEditPage = useCallback(
		(id) => {
			history.push(
				`/audience/edit/${id}`,
				state.audiences.find((audience) => audience.t1_id === id)
			);
		},
		[state]
	);

	/**
	 * get edit page URL by campaign id
	 */
	const getEditPageHref = useCallback((id) => `/audience/edit/${id}`, []);

	/**
	 * load audiences from API
	 * @param {object|null} pager
	 * @return {Promise<void>}
	 */
	const getList = async (pager = null) => {
		const audiences = services.current.get("audiences");
		try {
			setGridLoading(true);

			let params = {};
			if (pager) {
				params = Object.assign(params, pager.toJson());
			}

			if(query.current.length) {
				params["device_name"] = query.current;
			}

			const response = await audiences.list_by_agency(agency, params);
			/** @namespace response.data **/
			let meta = response.meta;
			meta.page = 1;

			dispatch({
				"type": audiencesActions.INIT,
				"data": response.data,
				"pager": meta,
			});
		} catch (e) {
			// ignore error
			console.log(e);
		} finally {
			if (_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * do a search
	 * @param e
	 * @param {string} value
	 */
	const handleSearch = async (e, {value: searchQuery}) => {
		let prev_query = query.current || "";
		query.current = searchQuery;

		// clear time any time we hit the method
		if(timer.current) {
			clearTimeout(timer.current);
		}

		if(!should_do_search(searchQuery, prev_query)) {
			return;
		}

		state.pager.reset();
		if(search_query_becomes_empty(searchQuery, prev_query)) {
			await getList(state.pager);
			return;
		}

		timer.current = setTimeout(async () => {
			await getList(state.pager);
		}, Config.search_debounce_delay);
	};

	return (
		<Segment basic style={{"padding": "0"}}>
			<AudiencesGridContext.Provider
				value={{
					navigateToCreatePage,
					navigateToEditPage,
					getEditPageHref,
					getPage, handleSearch,
					"showUploadButton": whitelabelUploadDeviceButton
				}}
			>
				{showSuccessMessage && (
					<AudiencesSuccessMessage
						details={history.location.state || {}}
					/>
				)}
				<h1>
					{intl.formatMessage({
						id: "HEADING_AUDIENCES",
						defaultMessage: "Audiences",
					})}
				</h1>
				<Divider hidden />
				<AudiencesGrid
					loading={gridLoading}
					items={state.audiences}
					controls={{"pager": state.pager}}
				/>
			</AudiencesGridContext.Provider>
		</Segment>
	);
};

/**
 * Generate success message
 * @param {function} onDismiss
 * @param {object} details
 * @return {*}
 * @constructor
 */
const AudiencesSuccessMessage = ({details }) => {
	const intl = useIntl();
	let timer;
	React.useEffect(() => {
		timer = setTimeout(onBannerDismiss, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	if (details.action === "created") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_AUDIENCE_CREATED",
					defaultMessage: "Audience successfully created",
				})}
			</Message>
		);
	} else if (details.action === "updated") {
		return (
			<Message
				success
				className="page-success-message"
				attached
				onDismiss={onBannerDismiss}
			>
				{intl.formatMessage({
					id: "MESSAGE_AUDIENCE_UPDATED",
					defaultMessage: "Audience {name} updated",
				}, {
					name: details.name,
				})}
			</Message>
		);
	}

	return null;
};
AudiencesSuccessMessage.propTypes = {
	"details": PropTypes.object.isRequired,
};

/**
 * Render grid
 * @param {array} items
 * @param {boolean} loading
 * @param {object} controls
 * @return {JSX.Element}
 * @constructor
 */
export const AudiencesGrid = ({ items, loading=false, controls }) => {
	const intl = useIntl();
	const context = React.useContext(AudiencesGridContext);
	const [value, setValue] = React.useState("");
	const _isMounted = React.useRef(false);
	const isUploadButton = context.showUploadButton;

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_search_input"
		}
	};

	// set effect to on query change, added listener to reset
	React.useLayoutEffect(() => {
		const el = document.getElementById("clear_search_input");
		if(el && value.length > 0) {
			el.addEventListener("click", clearSearch, false);
		}

		if(_isMounted.current) {
			(async() => {
				await context.handleSearch(null, {value});
			})();

		}

		return () => {
			if(el) {
				el.removeEventListener("click", clearSearch);
			}
		}
	}, [value]);

	// set component is mounted
	React.useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		}
	}, []);

	/**
	 * set search value and trigger search
	 * @param e
	 * @param value
	 * @return {Promise<void>}
	 */
	const handleSearch = async (e, {value}) => {
		setValue(value);
	};

	/**
	 * clear search query
	 */
	const clearSearch = () => {
		setValue("");
	};

	return (
		<>
			<Grid className="common_grid">
				<Grid.Row>
					<Grid.Column>
						<Menu pointing secondary>
							<Menu.Item
								as={NavLink}
								active={true}
								to="/audiences"
								content={intl.formatMessage({
									id: "LINK_DEVICE_ID",
									defaultMessage: "Device ID",
								})}
							/>
							<Menu.Item
								as={NavLink}
								to="/pixels"
								content={intl.formatMessage({
									id: "LINK_PIXELS",
									defaultMessage: "Pixels",
								})}
							/>
							{isUploadButton && <Menu.Menu position="right">
								<Menu.Item>
									<Button
										primary
										compact
										className="text__uppercase"
										onClick={() => context.navigateToCreatePage()}
									>
										{intl.formatMessage({
											id: "BTN_UPLOAD_DEVICE_ID",
											defaultMessage: "Upload Device ID",
										})}
									</Button>
								</Menu.Item>
							</Menu.Menu>}
						</Menu>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Segment basic style={{ "padding": "0" }} loading={loading}>
				<Grid className="common_grid">
					<Grid.Row>
						<Grid.Column>
							<Form
								autoComplete="off"
								noValidate
								size="tiny"
								style={{ "marginTop": "15px" }}
							>
								<Form.Group>
									<Form.Field>
										<label>
											{intl.formatMessage({
												id: "LABEL_DEVICE_ID_SEGMENTS",
												defaultMessage: "Device ID Segments",
											})}
										</label>
										<Input
											onChange={handleSearch}
											value={value}
											icon={value.length ? getCloseIcon() : null}
											placeholder={intl.formatMessage({
												id: "HINT_DEVICE_ID_SEGMENTS",
												defaultMessage: "Search device segment by name",
											})}
											name="strategy_name"
											style={{ "width": "250px" }}
										/>
									</Form.Field>
								</Form.Group>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Table className="custom-table">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								{intl.formatMessage({
									id: "LABEL_SEGMENT_NAME",
									defaultMessage: "Segment Name",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_STATUS",
									defaultMessage: "Status",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_SEGMENT_TYPE",
									defaultMessage: "Type",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_SEGMENT_ID",
									defaultMessage: "Segment ID",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="left">
								{intl.formatMessage({
									id: "LABEL_ADVERTISER",
									defaultMessage: "Advertiser",
								})}
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" style={{ "width": "20px" }}>
								&nbsp;
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{items.length ? (
							items.map((item) => {
								return <AudienceGridItem key={uuid()} {...item} />;
							})
						) : (
							<GridEmptyRow
								isUploadButton={isUploadButton}
								filterIsEmpty={!value}
								onAddButtonClick={context.navigateToCreatePage}
							/>
						)}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.Cell colSpan="12" textAlign="right">
								{controls.pager.total_pages > 1 && (
									<Pagination
										size="mini"
										activePage={controls.pager.page}
										totalPages={controls.pager.total_pages}
										firstItem={null}
										lastItem={null}
										onPageChange={context.getPage}
									/>
								)}
							</Table.Cell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</>
	);
};

/**
 * Generate grid item
 * @param {object} item
 * @return {*}
 * @constructor
 */
const AudienceGridItem = (item) => {
	const intl = useIntl();
	const context = React.useContext(AudiencesGridContext);

	return (
		<Table.Row className={item.status ? "" : " inactive"}>
			<Table.Cell className="grid-item-title">{item.title}</Table.Cell>
			<Table.Cell>
				{item.status
					? intl.formatMessage({
							id: "STATUS_ACTIVE",
							defaultMessage: "Active",
					  })
					: intl.formatMessage({
							id: "STATUS_INACTIVE",
							defaultMessage: "Inactive",
					  })}
			</Table.Cell>
			<Table.Cell>
				{/* type */}
				{intl.formatMessage({
					id: "SEGMENT_TYPE_DEVICE_ID",
					defaultMessage: "Device ID",
				})}
			</Table.Cell>
			<Table.Cell>{item.t1_id}</Table.Cell>
			<Table.Cell>{item.advertiser_name}</Table.Cell>
			<Table.Cell data-clickable="0" textAlign="center">
				<a
					href={context.getEditPageHref(item.t1_id)}
					onClick={(e) => {
						if (isNewTabClick(e)) return;
						e.preventDefault();
						e.stopPropagation();
						context.navigateToEditPage(item.t1_id);
					}}
				>
					<Icon name="edit" className="control" />
				</a>
			</Table.Cell>
		</Table.Row>
	);
};

const GridEmptyRow = ({ filterIsEmpty, onAddButtonClick, isUploadButton }) => {
	const intl = useIntl();
	const emptyDeviceMessage = (isUploadButton)? intl.formatMessage({
			id: "EMPTY_DEVICE_IDS",
			defaultMessage: "You don't have any audience segments yet",
		}) : intl.formatMessage({
			id: "EMPTY_DEVICE_ID_NO_UPLOAD_BUTTON",
			defaultMessage: `You don't have any audience segments yet. Please contact your {client_name} representative to use this.`,
		}, {
			"client_name": getReadableClientName(Config.public_client)
		});

	return (
		<Table.Row>
			<Table.Cell colSpan="5" textAlign="center">
				{filterIsEmpty ? (
					<>
						{emptyDeviceMessage}
						{isUploadButton && <>
							<br />
							<br />
							<Button
								className="text__uppercase"
								primary
								compact
								onClick={onAddButtonClick}
							>
								{intl.formatMessage({
									id: "BTN_UPLOAD_DEVICE_ID",
									defaultMessage: "Upload Device ID",
								})}
							</Button>
							</>}
					</>
				) : (
					intl.formatMessage({
						id: "EMPTY_SEARCH_RESULTS",
						defaultMessage: "No results found",
					})
				)}
			</Table.Cell>
		</Table.Row>
	);
};

GridEmptyRow.propTypes = {
	"filterIsEmpty": PropTypes.bool.isRequired,
	"onAddButtonClick": PropTypes.func.isRequired,
};
