import React, {useReducer, useState} from "react";
import StrategyManageContext from "../context";
import {LangContext} from "../../../../context";
import {Checkbox, Confirm, Divider, Form, Input, Message, Pagination, Radio, Segment, Table} from "semantic-ui-react";
import SupplyGridContext from "./contexts/supply";
import {OpenSupplyContext} from "./contexts/open_supply";
import Pager from "../../../../models/pager";
import {actions as sA, clearChecked, getCheckedSupplies, suppliesGridReducer} from "./reducers/supply";
import * as uuid from "uuid";
import {campaignHasPGType, formatMoney, getApiDate, isOk} from "../../../../libs/common_utils";
import {Config} from "../../../../config/api";
import PropTypes from "prop-types";
import StrategySuppliesSelected from "../../../../models/strategy_supplies_selected";
import {Types, RtbTypes} from "../../../../models/enum/supply";
import {NavigationButtonDiv} from "./navigation_buttons";
import {Steps} from "../../../../models/enum/strategy";
import StrategiesService from "../../../../services/strategy";
import {search_query_becomes_empty, should_do_search} from "../../../../libs/component_utils";

import {OpenSuppliesGrid} from "./supply/open_supplies";
import {actions as ops, getChecked as getCheckedOpenSupplies,
	clearChecked as openSupplyClearChecked,
	Reducer as openSuppliesReducer} from "./supply/reducers/open_supplies";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useIntl} from "react-intl";

const initialState = {
	"supplies": [],
	"open_supplies_market": [],
	"open_supplies_standard": [],
	"selected_supplies": {},
	"supplies_loaded": false,
	"open_supplies_loaded": false,
	"pager": new Pager()
};

const initialOpenState = {
	"open_supplies_market": [],
	"open_supplies_standard": [],
	"selected_supplies": {},
	"standard_loaded": false,
	"market_loaded": false
};

const radio_flags = new Map(Object.entries({
	"open_supply_hidden": false,
	"open_supply_disabled": false,
	"pmp_hidden": false,
	"pmp_disabled": false,
	"audience_hidden": false,
	"audience_disabled": false
}));

	/*	const open_supply_hidden = ((status !== Types.OPEN_SUPPLY && !is_edit_mode.current) && !whitelabelSupplyOpenFlag);
	const open_supply_disabled = !whitelabelSupplyOpenFlag;
	const pmp_hidden = ((status !== Types.PMP && !is_edit_mode.current) && !whitelabelSupplyPmpFlag);
	const pmp_disabled = !whitelabelSupplyPmpFlag;
	const audience_hidden = ((status !== SUPPLY_TYPE && !is_edit_mode.current) && !whitelabelSupplyAudienceFlag);
	const audience_disabled = !whitelabelSupplyAudienceFlag;*/

export const SupplyStep = () => {
	const context = React.useContext(StrategyManageContext);

	const lang_context = React.useContext(LangContext);
	const intl = useIntl();

	const service_common = context.services.current.get("strategies_common");
	const service = context.services.current.get("strategies");
	const strategy_id = context.strategy_id;
	let _isMounted = React.useRef(false);
	const supplies_has_been_blocked = React.useRef(false);
	const query = React.useRef(""),
	supply_query = React.useRef(""),
	timer = React.useRef(),
		is_edit_mode = React.useRef(false);

	const {whitelabelSupplyPgFlag, whitelabelSupplyPmpFlag,
		whitelabelSupplyAudienceFlag, whitelabelSupplyOpenFlag} = useFlags();

	const SUPPLY_TYPE = Config.strategy_supply_type;

	/**
	 * Get status that should be set by default based on LD flags
	 * @return {string}
	 */
	const getDefaultStatus = () => {
		if(campaignHasPGType(context.campaign)) {
			return Types.PG;
		}

		if(whitelabelSupplyOpenFlag) {
			return Types.OPEN_SUPPLY;
		}

		if(!whitelabelSupplyOpenFlag && !whitelabelSupplyAudienceFlag && whitelabelSupplyPmpFlag) {
			return Types.PMP;
		}

		return SUPPLY_TYPE;
	};

	const [confirmOpened, setConfirmOpened] = useState(false);
	const alreadyShowed = React.useRef(false);

	const [gridLoading, setGridLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [formSending, setFormSending] = useState(false);
	const [serverError, setServerError] = useState("");

	const [state, dispatch] = useReducer(suppliesGridReducer, initialState);
	const [openState, openDispatch] = useReducer(openSuppliesReducer, initialOpenState);
	const [status, setStatus] = useState(getDefaultStatus());
	const is_t1_edit = (service instanceof StrategiesService);

	let get_continue = React.useRef(true);
	/**
	 * Save local strategy
	 * @param {boolean} is_continue
	 */
	const setOperationType = (is_continue=true) => {
		get_continue.current = is_continue;
	};

	// reset error each time user change status type
	// load supplies 1 time when user choose pmp
	React.useEffect(() => {
		setServerError("");
		if(suppliesGridToBeShown(status) && !state.supplies_loaded) {
			state.pager.reset();
			(async () => {
				await getSupplies(state.pager);
			})();
		} else if(status === Types.OPEN_SUPPLY && !openState.market_loaded) {
			(async () => {
				await getMarketSupplies();
			})();
		}
	}, [status]);

	// mount component
	React.useEffect(() => {
		supplies_has_been_blocked.current = false;
		radio_flags.set("pmp_disabled", !whitelabelSupplyPmpFlag);
		radio_flags.set("audience_disabled", !whitelabelSupplyAudienceFlag);
		radio_flags.set("open_supply_disabled", !whitelabelSupplyOpenFlag);

		radio_flags.set("open_supply_hidden", (status !== Types.OPEN_SUPPLY) && !whitelabelSupplyOpenFlag);
		radio_flags.set("pmp_hidden", (status !== Types.PMP) && !whitelabelSupplyPmpFlag);
		radio_flags.set("audience_hidden", (status !== SUPPLY_TYPE) && !whitelabelSupplyAudienceFlag);

		if(!strategy_id) {
			return;
		}

		(async () => {
			try {
				const r = await service.get_supplies(strategy_id);
				if(isOk(r)) {
					if(r.data.supply_type === Types.OPEN_SUPPLY) {
						openDispatch({"type": ops.LOAD_SAVED, "data": r.data});
					} else {
						dispatch({"type": sA.LOAD_SAVED_DATA, "data": r.data});
					}

					if(Boolean(r.data.supply_type.length)) {
						const status = r.data.supply_type;
						radio_flags.set("open_supply_hidden", (status !== Types.OPEN_SUPPLY) && !whitelabelSupplyOpenFlag);
						radio_flags.set("pmp_hidden", (status !== Types.PMP) && !whitelabelSupplyPmpFlag);
						radio_flags.set("audience_hidden", (status !== SUPPLY_TYPE) && !whitelabelSupplyAudienceFlag);

						is_edit_mode.current = true;

						setStatus(status);
					} else {
						await getStandardSupplies();
						switchAllMarket(true);
						switchAllStandard(true);
					}
				}
			} catch (e) {
				supplies_has_been_blocked.current = Boolean(~e.error.message.toLowerCase().search(/have been changed outside/));
				setServerError(e.error.message);
			} finally {
				setPageLoading(false);
			}
		})();

		_isMounted.current = true;
		// clear cache
		return () => {
			state.pager.reset();
			clearChecked();
			openSupplyClearChecked();
			_isMounted.current = false;
		}
	}, [strategy_id]);

	React.useLayoutEffect(() => {
		const el = document.getElementById("clear_search_input"),
			el_supply = document.getElementById("clear_supply_search_input");

		if(query.current.length > 0) {
			el.addEventListener("click", clearSearch, false);
		}

		if(supply_query.current.length > 0) {
			el_supply.addEventListener("click", clearSupplySearch, false);
		}

		return () => {
			if(el) {
				el.removeEventListener("click", clearSearch);
			}

			if(el_supply) {
				el_supply.removeEventListener("click", clearSupplySearch);
			}
		}
	}, [query.current, supply_query.current]);

	// redesign confirmation buttons
	React.useLayoutEffect(() => {
		if(confirmOpened) {
			document.querySelectorAll("#open_supply_confirm button").forEach(n => {
				n.classList.add("mini");
			});
		}
	}, [confirmOpened]);

	/**
	 * get page
	 * @param e
	 * @param activePage
	 */
	const getPage = (e, {activePage}) => {
		state.pager.setPage(activePage);
		getSupplies(state.pager).then(() => console.log);
	};

	/**
	 * load supplies from API
	 * @param {object} pager
	 * @return {Promise<void>}
	 */
	const getSupplies = async (pager) => {
		try {
			setGridLoading(true);

			let params = pager.toJson();
			params["currency_code"] = context.campaign.currency_code;
			params["deal_type"] = campaignHasPGType(context.campaign)? Types.PG : Types.PMP;
			if(query.current.length) {
				params["supply_name"] = query.current;
			}

			const r = await service_common.supplies_by_advertiser(context.campaign.advertiser_id, params);
			let meta = r.meta;
			meta.page = pager.page;
			dispatch({"type": sA.INIT, "data": r.data, "pager": meta});
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * load open supplies from API
	 * @param {string} rtb_type
	 * @param {string} media_type
	 * @return {Promise<void>}
	 */
	const getOpenSupplies = async (rtb_type, media_type) => {
		try {
			setGridLoading(true);

			const r = await service_common.get_open_supplies(rtb_type, media_type, supply_query.current);
			if(rtb_type.toLowerCase() === "marketplace") {
				openDispatch({"type": ops.INIT_MARKETPLACE, "data": r.data});
			} else if(rtb_type.toLowerCase() === "standard") {
				openDispatch({"type": ops.INIT_STANDARD, "data": r.data});
			}
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * Search open supplies
	 * We need custom loader to handle grid loader
	 * @return {Promise}
	 */
	const searchOpenSupply = async () => {
		try {
			setGridLoading(true);

			const [r1, r2] = await Promise.all([service_common.get_open_supplies(RtbTypes.MARKET.toLowerCase(), context.strategy.media_type, supply_query.current),
				service_common.get_open_supplies(RtbTypes.STANDARD.toLowerCase(), context.strategy.media_type, supply_query.current)]);

			openDispatch({"type": ops.SEARCH, "data": [r1.data, r2.data]});
		} catch (e) {
			setServerError(e.error.message);
		} finally {
			if(_isMounted.current) {
				setGridLoading(false);
			}
		}
	};

	/**
	 * load market supplies
	 * @return {Promise<void>}
	 * @constructor
	 */
	const getMarketSupplies = async () => {
		await getOpenSupplies(RtbTypes.MARKET.toLowerCase(), context.strategy.media_type);
	};

	/**
	 * load standard supplies
	 * @return {Promise<void>}
	 * @constructor
	 */
	const getStandardSupplies = async () => {
		await getOpenSupplies(RtbTypes.STANDARD.toLowerCase(), context.strategy.media_type);
	};

	/**
	 * switch all market checkboxes
	 */
	const switchAllMarket = flag => {
		openDispatch({"type": ops.SWITCH_ALL, "target": RtbTypes.MARKET, "flag": flag});
	};

	/**
	 * switch all standard checkboxes
	 */
	const switchAllStandard = flag => {
		openDispatch({"type": ops.SWITCH_ALL, "target": RtbTypes.STANDARD, "flag": flag});
	};

	/**
	 * switch market checkbox
	 */
	const switchMarket = (id, flag) => {
		openDispatch({"type": ops.CHECK, "target": RtbTypes.MARKET, "id": id, "flag": flag});
	};

	/**
	 * switch standard checkboxes
	 */
	const switchStandard = (id, flag) => {
		openDispatch({"type": ops.CHECK, "target": RtbTypes.STANDARD, "id": id, "flag": flag});
	};

	/**
	 * check specific supply
	 * @param {number} id
	 * @param {boolean} flag
	 */
	const check = (id, flag) => {
		dispatch({"type": sA.CHECK, "id": id, "flag": flag});
	};

	/**
	 * form submit handler
	 * @return {boolean}
	 */
	const onSubmit = () => {
		setServerError("");

		const checked = (status === Types.OPEN_SUPPLY)? getCheckedOpenSupplies() : getCheckedSupplies();
		const supplies = StrategySuppliesSelected.fromJson(strategy_id, {
			"supply_ids": Array.from(checked.keys()),
			"supply_type": status
		});

		if(!supplies.isValid()) {
			setServerError(intl.formatMessage({
				id: "ERROR_EMPTY_SUPPLIES",
				defaultMessage: "At least one supply must be selected.",
			}));
			return false;
		}

		(async () => {
			try {
				setFormSending(true);

				let response;
				if(is_edit_mode.current) {
					response = await service.update_supplies(supplies.strategy_id, supplies.toJson());
				} else {
					response = await service.create_supplies(supplies.strategy_id, supplies.toJson());
				}

				if(isOk(response)) {
					if(get_continue.current) {
						context.stepNavigation.passSupplies(strategy_id);
					} else {
						context.getBack(true);
					}
				}
			} catch(e) {
				setServerError(e.error.message);
			} finally {
				if(_isMounted.current) {
					setFormSending(false);
				}
			}
		})();
	};

	/**
	 * check that grid to be displayed
	 * @param {String} status
	 * @return {boolean}
	 */
	const suppliesGridToBeShown = status => {
		return Boolean(status === Types.PMP || campaignHasPGType(context.campaign));
	};

	/**
	 * do a search
	 */
	const handleSearch = async (e, {value: searchQuery}) => {
		let prev_query = query.current || "";
		query.current = searchQuery;

		// clear time any time we hit the method
		if(timer.current) {
			// service_common.api_server.cancelRequest();
			clearTimeout(timer.current);
		}

		if(!should_do_search(searchQuery, prev_query)) {
			return;
		}

		state.pager.reset();
		if(search_query_becomes_empty(searchQuery, prev_query)) {
			await getSupplies(state.pager);
			return;
		}

		timer.current = setTimeout(async () => {
			await getSupplies(state.pager);
		}, Config.search_debounce_delay);
	};

	/**
	 * do an open supply search
	 * @param {React.SyntheticEvent} e
	 * @param {object} props
	 * @param {string} props.value
	 */
	const handleOpenSupplySearch = async (e, {value}) => {
		let prev_query = supply_query.current || "";
		supply_query.current = value;

		// clear time any time we hit the method
		if(timer.current) {
			clearTimeout(timer.current);
		}

		if(!should_do_search(value, prev_query)) {
			return;
		}

		if(search_query_becomes_empty(value, prev_query)) {
			await searchOpenSupply();
			return;
		}

		timer.current = setTimeout(async () => {
			await searchOpenSupply();
		}, Config.search_debounce_delay);
	};

	/**
	 * clear search query
	 */
	const clearSupplySearch = async () => {
		supply_query.current = "";
		document.getElementById("search_supply_query_id").value = "";
		service_common.api_server.cancelRequest();
		await searchOpenSupply();
	};

	/**
	 * clear search query
	 */
	const clearSearch = async () => {
		query.current = "";
		document.getElementById("search_query_id").value = "";
		state.pager.reset();
		service_common.api_server.cancelRequest();
		await getSupplies(state.pager);
	};

	/**
	 * generate close icon
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_search_input"
		}
	};

	/**
	 * generate close icon for open_supply
	 * @returns {{onClick: *, name: string}}
	 */
	const getCloseSupplyIcon = () => {
		return {
			"name": "close",
			"link": true,
			"id": "clear_supply_search_input"
		}
	};

	/**
	 * generate grid component
	 * @param {number} status
	 * @return {JSX.Element}
	 */
	const generateGridComponent = status => {
		if(status === Types.PMP || campaignHasPGType(context.campaign)) {
			const enabled = (status === Types.PMP)? whitelabelSupplyPmpFlag : whitelabelSupplyPgFlag;
			return <SuppliesGrid items={state.supplies}
													 loading={gridLoading}
													 enabled={enabled}
													 controls={{"pager": state.pager}} />
		} else if(status === Types.OPEN_SUPPLY) {
			return <OpenSupplyContext.Provider value={{
				GetMarketSupplies: getMarketSupplies, GetStandardSupplies: getStandardSupplies, switchAllMarket, switchAllStandard,
				switchMarket, switchStandard, "enabled": whitelabelSupplyOpenFlag
			}}>
				<OpenSuppliesGrid {...openState}
													new_form_mode={!is_edit_mode.current}
													media_type={context.strategy.media_type} />
			</OpenSupplyContext.Provider>
		}

		return <SuppliesHANComponent text={lang_context.strategy.supply_type_text} />;
	};

	/**
	 * handle changing media type after user confirm change
	 */
	const handleChangeOpenSupplyConfirmed = () => {
		alreadyShowed.current = true;
		setConfirmOpened(false);
		setStatus(Types.OPEN_SUPPLY);
	}

	/**
	 * change status to open supply
	 */
	const handleOpenSupplyChanged = () => {
		if((!is_edit_mode && !is_t1_edit) || alreadyShowed.current) {
			setStatus(Types.OPEN_SUPPLY);
		} else {
			setConfirmOpened(true);
		}
	};

	if(pageLoading) {
		return (<Segment disabled tertiary textAlign="center" className="loading" style={{"height": "300px"}}>
			&nbsp;
		</Segment>);
	}

	return (
		<SupplyGridContext.Provider value={{
			getPage, check
		}}>
			<Message
				style={{"marginTop": "10px"}}
				error
				hidden={!serverError}
				size="tiny"
				content={serverError}
			/>
			<Form onSubmit={onSubmit} error={Boolean(serverError.length)} size="small" loading={formSending || gridLoading} noValidate autoComplete="off">
				<div style={{"display": supplies_has_been_blocked.current? "none" : "block"}}>
					<Form.Group>
						<Form.Field inline style={{"margin": "auto 0"}}>
							<label>
								{intl.formatMessage({
									id: "LABEL_SUPPLY_TYPE",
									defaultMessage: "Supply Type",
								})}
							</label>
							{campaignHasPGType(context.campaign)?
								"Programmatic Guaranteed" :
								<>
									<Confirm
										open={confirmOpened}
										content={intl.formatMessage({
											id: "BODY_CONFIRM_OPEN_SUPPLY",
											defaultMessage: "Changing to Open Supply will clear all audience segments. Are you sure you want to continue?",
										})}
										cancelButton={intl.formatMessage({
											id: "BTN_CANCEL",
											defaultMessage: "Cancel",
										})}
										confirmButton={intl.formatMessage({
											id: "BTN_OK",
											defaultMessage: "OK",
										})}
										onCancel={() => setConfirmOpened(false)}
										onConfirm={handleChangeOpenSupplyConfirmed}
										id="open_supply_confirm"
										size="mini"
									/>
									{!radio_flags.get("open_supply_hidden") && <Radio
										name="status"
										label={intl.formatMessage({
											id: "SUPPLY_TYPE_OPEN",
											defaultMessage: "Open Supply",
										})}
										value={Types.OPEN_SUPPLY}
										checked={status === Types.OPEN_SUPPLY}
										disabled={radio_flags.get("open_supply_disabled")}
										onChange={handleOpenSupplyChanged}
									/>}
									{!radio_flags.get("audience_hidden") && <Radio
										style={{"marginLeft": "15px"}}
										name="status"
										label={lang_context.strategy.supply_type_title}
										value={SUPPLY_TYPE}
										checked={status === SUPPLY_TYPE}
										disabled={radio_flags.get("audience_disabled")}
										onChange={() => setStatus(SUPPLY_TYPE)}
									/>}
									{!radio_flags.get("pmp_hidden") && <Radio
										style={{"marginLeft": "15px"}}
										name="status"
										label={intl.formatMessage({
											id: "SUPPLY_TYPE_PMP",
											defaultMessage: "Private Marketplace",
										})}
										value={Types.PMP}
										checked={status === Types.PMP}
										disabled={radio_flags.get("pmp_disabled")}
										onChange={() => setStatus(Types.PMP)}
									/>}
								</>
							}
						</Form.Field>
						<Form.Field inline className={(campaignHasPGType(context.campaign) || status === Types.PMP)? "" : "invisible"}>
							<Input
								onChange={handleSearch}
								placeholder={intl.formatMessage({
									id: "HINT_SEARCH_DEAL",
									defaultMessage: "Search deal by name",
								})}
								icon={query.current? getCloseIcon() : null}
								name="search_query"
								id="search_query_id"
								style={{"width": "250px"}}
								disabled={gridLoading}
							/>
						</Form.Field>
						<Form.Field inline className={status === Types.OPEN_SUPPLY? "" : "invisible"}>
							<Input
								onChange={handleOpenSupplySearch}
								placeholder={intl.formatMessage({
									id: "HINT_SEARCH_SUPPLY",
									defaultMessage: "Search supply by name",
								})}
								icon={supply_query.current? getCloseSupplyIcon() : null}
								name="search_supply_query"
								id="search_supply_query_id"
								style={{"width": "250px"}}
								disabled={gridLoading}
							/>
						</Form.Field>
					</Form.Group>
					{!pageLoading && generateGridComponent(status)}
				</div>
				<Divider hidden />
				<Divider hidden />
				<Divider hidden />
				<NavigationButtonDiv
					loading={formSending || gridLoading}
					step={Steps.SUPPLY}
					isPG={context.campaign.is_pg}
					onSave={setOperationType}
					onContinueClick={() => context.stepNavigation.passSupplies(strategy_id)}
					is_t1_edit={service instanceof StrategiesService}
					onBackClick={() => context.stepNavigation.backToMarketingObjective(strategy_id)}
					onCancelClick={context.getBack}
					skip_save_buttons={supplies_has_been_blocked.current}
				/>
			</Form>
		</SupplyGridContext.Provider>);
};

const SuppliesHANComponent = ({text}) => {
	return <div dangerouslySetInnerHTML={{__html: text}}></div>;
};

/**
 * Render supplies grid
 * @constructor
 */
const SuppliesGrid = ({items, enabled, loading, controls}) => {
	const intl = useIntl();
	const context = React.useContext(SupplyGridContext);

	return (
		<Table className="custom-table">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell textAlign="center" style={{"width": "20px"}}>&nbsp;</Table.HeaderCell>
					<Table.HeaderCell>
						{intl.formatMessage({
							id: "LABEL_DEAL_NAME",
							defaultMessage: "Deal Name",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell textAlign="left">
						{intl.formatMessage({
							id: "LABEL_DEAL_ID",
							defaultMessage: "Deal ID",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell textAlign="left" width={2}>
						{intl.formatMessage({
							id: "LABEL_CPM",
							defaultMessage: "CPM",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell textAlign="left" style={{"width": "100px"}}>
						{intl.formatMessage({
							id: "LABEL_START_DATE",
							defaultMessage: "Start Date",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell textAlign="left" style={{"width": "100px"}}>
						{intl.formatMessage({
							id: "LABEL_END_DATE",
							defaultMessage: "End Date",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell textAlign="left" style={{"width": "250px"}}>
						{intl.formatMessage({
							id: "LABEL_SUPPLY_SOURCE",
							defaultMessage: "Supply Source",
						})}
					</Table.HeaderCell>
					<Table.HeaderCell textAlign="left" width={1}>
						{intl.formatMessage({
							id: "LABEL_DEAL_TYPE",
							defaultMessage: "Deal Type",
						})}
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{items.map(item => {
					return <SupplyGridItem key={uuid()} {...item} enabled={enabled} />
				})}
				<GridEmptyRow
					length={items.length}
					loading={loading} />
			</Table.Body>
			<Table.Footer>
				<Table.Row>
					<Table.Cell colSpan="8" textAlign="right">{controls.pager.total_pages > 1 && <Pagination
						size="mini"
						activePage={controls.pager.page}
						totalPages={controls.pager.total_pages}
						firstItem={null}
						lastItem={null}
						onPageChange={context.getPage}
					/>}</Table.Cell>
				</Table.Row>
			</Table.Footer>
		</Table>
	)
};
SuppliesGrid.propTypes = {
	"items": PropTypes.array.isRequired,
	"enabled": PropTypes.bool.isRequired,
	"loading": PropTypes.bool.isRequired,
	"controls": PropTypes.object.isRequired
};

const GridEmptyRow = ({length, loading}) => {
	const intl = useIntl();

	if(length > 0 || loading) {
		return <></>;
	}

	return (
		<Table.Row>
			<Table.Cell className="no-border" colSpan="8" textAlign="center">
				{intl.formatMessage({
					id: "EMPTY_SEARCH_RESULTS",
					defaultMessage: "No results found",
				})}
			</Table.Cell>
		</Table.Row>
	);
};
GridEmptyRow.propTypes = {
	"length": PropTypes.number.isRequired,
	"loading": PropTypes.bool.isRequired
};

/**
 * Generate grid item
 */
const SupplyGridItem = item => {
	const context = React.useContext(SupplyGridContext);
	const end_date = (Boolean(~item.end_date.search(/^2999/)))? "Never" : getApiDate(item.end_date, Config.dateTimeFormat);

	return (
		<>
			<Table.Row>
				<Table.Cell data-clickable="0" textAlign="center">
					<Checkbox defaultChecked={item.checked} disabled={!item.enabled} data_id={item.id} onChange={(e, {data_id, checked}) => {
						context.check(data_id, checked);
					}} />
				</Table.Cell>
				<Table.Cell className="text-ellipsis">{item.title}</Table.Cell>
				<Table.Cell className="text-ellipsis">{item.deal_id}</Table.Cell>
				<Table.Cell><SupplyPrice item={item} /></Table.Cell>
				<Table.Cell>{getApiDate(item.start_date, Config.dateTimeFormat)}</Table.Cell>
				<Table.Cell>{end_date}</Table.Cell>
				<Table.Cell>{item.supply_source_name}</Table.Cell>
				<Table.Cell>{item.deal_type}</Table.Cell>
			</Table.Row>
		</>
	);
};

/**
 * form supply price
 * @param item
 * @return {string}
 * @constructor
 */
const SupplyPrice = ({item}) => {
	return `${formatMoney(item.price, item.currency, Config.defaultLocale, 2)} ${item.price_type}`;
};
SupplyPrice.propTypes = {
	"item": PropTypes.shape({
		"currency": PropTypes.string.isRequired,
		"price": PropTypes.number.isRequired,
		"price_type": PropTypes.string.isRequired
	}).isRequired
};
