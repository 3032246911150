import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Button, Table } from "semantic-ui-react";

const GridEmptyRow = ({
	length,
	loading,
	notFoundMessage,
	filterTouched,
	addButtonLabel,
	handleAddButton,
}) => {
	const intl = useIntl();

	if (length > 0 || loading) {
		return null;
	}
	const emptyMessage = !filterTouched
		? notFoundMessage
		: intl.formatMessage({
				id: "EMPTY_SEARCH_RESULTS",
				defaultMessage: "No results found",
		  });

	if (!filterTouched) {
		return (
			<Table.Row>
				<Table.Cell className="no-border" colSpan="12" textAlign="center">
					{emptyMessage}
					<br />
					<br />
					<Button
						className="text__uppercase"
						primary
						compact
						onClick={handleAddButton}
					>
						{addButtonLabel}
					</Button>
				</Table.Cell>
			</Table.Row>
		);
	}

	return (
		<Table.Row>
			<Table.Cell className="no-border" colSpan="12" textAlign="center">
				{emptyMessage}
			</Table.Cell>
		</Table.Row>
	);
};

GridEmptyRow.propTypes = {
	length: PropTypes.number.isRequired,
	loading: PropTypes.bool.isRequired,
	handleAddButton: PropTypes.func.isRequired,
	filterTouched: PropTypes.bool.isRequired,
	notFoundMessage: PropTypes.string.isRequired,
	addButtonLabel: PropTypes.string.isRequired,
};

export default GridEmptyRow;
