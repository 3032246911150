import React, {useContext, useState} from "react";
import {OnlineContext} from "../context/online-context";
import {Menu, Icon, Label} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {Config} from "../config/api";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useIntl} from "react-intl";
import LocalePicker from "./locale/picker";
import CreativesService from "../services/creatives";

const HeaderMenu = () => {
	const {
		online,
		agencyTitle,
		setAgencyPickerOpen,
		doLogout,
		internal,
		isReporter,
		currentUserIsAdmin,
		username
	} = useContext(OnlineContext);
	const [showMenu, setShowMenu] = useState(false);
	const {whitelabelShowSupportLink, whitelabelShowTc, whitelabelDoubleCreativeApproval} = useFlags();

	let pdfFile = "";
	try {
		const TCLoader = client => {
			return require(`../assets/client/${client}/terms_and_conditions.pdf`);
		}
		pdfFile = (whitelabelShowTc)? TCLoader(Config.public_client) : ""
	} catch (e) {
		console.error(e);
	}


	/**
	 * handle logout click
	 * @param e
	 */
	const handleLogout = (e) => {
		e.preventDefault();
		doLogout();
	};

	return (
		<>
			<div>
				<Menu
					inverted
					fluid
					className="top-menu"
					size="small"
					style={{"height": "56px"}}
				>
					<Menu.Header>
						{online && !isReporter &&
							<div className="menu-header-icon">
								<Icon
									name="bars"
									size="big"
									onClick={() => setShowMenu(!showMenu)}
								/>
							</div>
						}
						<NavLink to="/">
							<div className="menu-logo">&nbsp;</div>
						</NavLink>
					</Menu.Header>
					<AuthorizedMenu
						is_online={online}
						username={username}
						logoutFn={handleLogout}
						agencyTitle={agencyTitle}
						openAgencyPicker={() => setAgencyPickerOpen(true)}
					/>
				</Menu>
			</div>
			<NavigationMenu
				opened={showMenu}
				hideMenu={() => setShowMenu(false)}
				internal={internal}
				supportLinkEnabled={whitelabelShowSupportLink}
				showAdminSection={currentUserIsAdmin}
				pdfFile={pdfFile}
				doubleCreativeApproval={whitelabelDoubleCreativeApproval}
			/>
		</>
	);
};

/**
 * Menu generated based on user authorization status
 * @param {boolean} is_online
 * @param {boolean} agencyTitle
 * @param {string} username
 * @param {function} openAgencyPicker
 * @param {function} logoutFn
 * @return {*}
 * @constructor
 */
const AuthorizedMenu = ({is_online, agencyTitle, username, openAgencyPicker, logoutFn}) => {
	const intl = useIntl();
	const {whitelabelShowTranslation} = useFlags();

	if(is_online) {
		return (
			<Menu.Menu position="right">
				{whitelabelShowTranslation && <LocalePicker item className="top-bar-language-picker" />}
				<Menu.Item onClick={openAgencyPicker}>
					{agencyTitle}
					<Icon name="dropdown" style={{ marginLeft: ".5em" }} />
				</Menu.Item>
				<Menu.Item as={NavLink} to="/profile">
					<Icon name="user circle outline" style={{"fontSize": "large"}} /> {username.replace(/@.*?$/, "").replace(/-{2}at-{2}/g, "@")}
				</Menu.Item>
				<Menu.Item as={NavLink} to="/logout" onClick={logoutFn}>
					{intl.formatMessage({
						id: "LINK_LOGOUT",
						defaultMessage: "Logout",
					})}
				</Menu.Item>
			</Menu.Menu>
		)
	} else {
		return <></>;
	}
};
AuthorizedMenu.propTypes = {
	"is_online": PropTypes.bool.isRequired,
	"logoutFn": PropTypes.func.isRequired
};

/**
 * Show/hide navigation menu
 * @param {boolean} opened
 * @param {function} hideMenu
 * @param {boolean} internal
 * @param {boolean} showAdminSection
 * @param {boolean} supportLinkEnabled
 * @param {boolean} doubleCreativeApproval
 * @param {string} pdfFile
 * @return {*}
 * @constructor
 */
const NavigationMenu = ({opened, hideMenu, internal, showAdminSection,
													supportLinkEnabled, pdfFile, doubleCreativeApproval}) => {
	const intl = useIntl();
	React.useEffect(() => {
		document.getElementById("header-burger-menu-id").addEventListener("mouseleave", () => {
			// hideMenu();
		});
	}, []);

	/**
	 * generate link for admin section
	 * @param {Boolean} isInternal
	 * @param {Boolean} showCreativeApproval
	 * @return {JSX.Element}
	 */
	const getAdminSection = (isInternal, showCreativeApproval) => {
		return (
			<>
				<Menu.Item header style={{ "borderBottom": "1px solid white" }}>
					{intl.formatMessage({
						id: "HEADING_ADMIN",
						defaultMessage: "Admin",
					})}
				</Menu.Item>
				{isInternal && (
					<Menu.Item as={NavLink} to="/admin/margins" onClick={hideMenu}>
						{intl.formatMessage({
							id: "LINK_MARGIN_CONTROLS",
							defaultMessage: "Margin Controls",
						})}
					</Menu.Item>
				)}
				<Menu.Item as={NavLink} to="/admin/users" onClick={hideMenu}>
					{intl.formatMessage({
						id: "LINK_USERS",
						defaultMessage: "Users",
					})}
				</Menu.Item>
				{showCreativeApproval && <Menu.Item as={NavLink} to="/admin/onsite-creatives" onClick={hideMenu}>
					{intl.formatMessage({
						id: "LINK_CREATIVE_APPROVAL",
						defaultMessage: "Creative Approval",
					})}
					<PendingCreativesBalloon />
				</Menu.Item>}
			</>
		);
	};

	return (
		<Menu
			vertical
			id="header-burger-menu-id"
			borderless
			fixed="left"
			className={!opened ? "invisible nav-menu" : "nav-menu"}
		>
			<Menu.Item as={NavLink} to="/campaigns" onClick={hideMenu}>
				{intl.formatMessage({
					id: "LINK_CAMPAIGNS",
					defaultMessage: "Campaigns",
				})}
			</Menu.Item>
			<Menu.Item as={NavLink} to="/creatives" onClick={hideMenu}>
				{intl.formatMessage({
					id: "LINK_CREATIVES",
					defaultMessage: "Creatives",
				})}
			</Menu.Item>
			<Menu.Item as={NavLink} to="/audiences" onClick={hideMenu}>
				{intl.formatMessage({
					id: "LINK_AUDIENCES",
					defaultMessage: "Audiences",
				})}
			</Menu.Item>
			<Menu.Item as={NavLink} to="/inventory" onClick={hideMenu}>
				{intl.formatMessage({
					id: "LINK_INVENTORY",
					defaultMessage: "Inventory",
				})}
			</Menu.Item>
			<Menu.Item as={NavLink} to="/reports" onClick={hideMenu}>
				{intl.formatMessage({
					id: "LINK_REPORT",
					defaultMessage: "Report",
				})}
			</Menu.Item>
			{pdfFile && (
				<Menu.Item>
					<a href={pdfFile} target="_blank" style={{"color": "white"}} onClick={hideMenu}>
						{intl.formatMessage({
							id: "LINK_TERMS_AND_CONDITIONS",
							defaultMessage: "Terms and Conditions",
						})}
					</a>
				</Menu.Item>
			)}
			{showAdminSection && getAdminSection(internal, doubleCreativeApproval)}
			{supportLinkEnabled && (
				<Menu.Item
					target="_blank"
					href={Config.support_link}
					style={{ "borderTop": "1px solid white" }}
					onClick={hideMenu}
				>
					{intl.formatMessage({
						id: "LINK_CONTACT_SUPPORT",
						defaultMessage: "Contact Support",
					})}
				</Menu.Item>
			)}
		</Menu>
	);
};
NavigationMenu.propTypes = {
	"opened": PropTypes.bool.isRequired,
	"hideMenu": PropTypes.func.isRequired,
	"internal": PropTypes.bool,
	"showAdminSection": PropTypes.bool,
	"supportLinkEnabled": PropTypes.bool,
	"permissions": PropTypes.arrayOf(PropTypes.string),
};

const PendingCreativesBalloon = () => {
	const services = React.useRef(new Map([["creatives", new CreativesService()]]));
	const [pendingCreatives, setPendingCreatives] = useState(0);

	React.useEffect(() => {
		(async () => {
			try {
				const r = await services.current.get("creatives").get_pending_amount();
				setPendingCreatives(r.data["pending_creatives"]);
			} catch (e) {}
		})();
	}, []);

	return pendingCreatives > 0? <Label circular color="yellow" size="tiny">
		{pendingCreatives > 9? "+9" : pendingCreatives}
	</Label> : <></>
};

export default HeaderMenu;
