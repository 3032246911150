import React, { useContext, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { LocaleContext } from "../../context/locale-context.js";

export default function LocalePicker({ ...rest }) {
	const { availableLocales, currentLocale, setCurrentLocale } = useContext(LocaleContext);

	return availableLocales !== null ? (
		<Dropdown
			options={availableLocales.map(({ locale, name }) => ({
				key: locale,
				text: name,
				value: locale,
			}))}
			text={
				availableLocales.find(({ locale }) => locale === currentLocale)?.name
				|| currentLocale
			}
			value={currentLocale}
			onChange={(e, { value }) => setCurrentLocale(value)}
			{...rest}
		/>
	) : null;
}

LocalePicker.propTypes = Dropdown.propTypes;
